import React from "react"
import { Row, Col } from "react-bootstrap"

import MgcSelectField from "./mgcSelectField"
class DeathBenefitCapital extends React.Component {
  render() {
    const { tab, values, dirty, errors, tabMode, conjoint, setFieldValue } = this.props
    return (
      <>
        <Col xs={12}>
          <MgcSelectField
            deathBenefit={true}
            dirty={true}
            errors={errors}
            name={conjoint ? "capitalSouhaiteConjoint" : "capitalSouhaite"}
            firstOption="- Capital souhaité -"
            className=""
            table={tab}
            setFieldValue={setFieldValue}
          />
        </Col>

        <Col xs={12}>
          <MgcSelectField
            dirty={true}
            errors={errors}
            name={conjoint ? "modeObsequeConjoint" : "modeObseque"}
            firstOption="- Mode d'obsèques envisagé -"
            className=""
            table={tabMode}
            setFieldValue={setFieldValue}
          />
        </Col>
      </>
    )
  }
}
export default DeathBenefitCapital
