import React from "react";
import { ATInternetTagService } from '../services/global/ATInternetService';

const CibleFormulesGammeButton = ({textButton, linkButton}) => (
  <div className="text-center">
    <a href={linkButton} target="_blank" className="buttonUpload" onClick={() => ATInternetTagService.sendDnClick({click:textButton})}>{textButton}</a>
  </div>
)


export default CibleFormulesGammeButton;
