import React, { useEffect, useState } from "react"
import RacSimulatorView from "./RacSimulatorView";
import SimulateurService from "../../services/simulateur/simulateurService";

const RazSimulatorBlock = ({ data, showTitle }) => {
    const mgcgestionFormulaId = (data && data.mgcgestionFormulaId) ? data.mgcgestionFormulaId : null;
    const mgcgestionGammeId = (data && data.mgcgestionGammeId) ? data.mgcgestionGammeId : null;

    const body = {
        "formuleId": null,
        "gammeId": null,
        "prestationId": null,
        "sousNiveauSoinId": null,
        "depense": null,
        "typeSoin": null,
        "situationProfessionnelleId": null
    };
    
    const [simulatorBody, setSimulatorBody] = useState(body);
    const [simulatorValues, setSimulatorValues] = useState(null);

    const typeSoins = [
        { value: "SOIN_COURANT", icon: "fas fa-user-md", isSvg: false, text: "Soins courants" },
        { value: "HOSPITALISATION", icon: "icon-hospital", isSvg: false, text: "Hospitalisation" },
        { value: "OPTIQUE", icon: "fas fa-glasses", isSvg: false, text: "Optique" },
        { value: "DENTAIRE", icon: "fas fa-tooth", isSvg: false, text: "Dentaire" },
        { value: "AIDE_AUDITIVES", icon: "fas fa-assistive-listening-systems", isSvg: false, text: "Aides auditives" },
        { value: "MEDECINE_DOUCES", icon: "", isSvg: true, text: "Médecines alternatives" }
    ];

    useEffect(() => {
        const formuleId = mgcgestionFormulaId;
        const gammeId = mgcgestionGammeId;
        simulatorBody.formuleId = formuleId;
        simulatorBody.gammeId = gammeId;
        simulatorBody.typeSoin = typeSoins[0].value;

        setSimulatorBody(simulatorBody);
        getSimulateurValues(simulatorBody);
    }, []);

    function getSimulateurValues(body) {
        SimulateurService.submit(body).then(
            result => {
                setSimulatorValues(result.data);
            },
            reason => {
                // console.log(reason);
            }
        )
    }

    function handleChange(params) {
        console.log(params)
        params.forEach(param => {
            Object.entries(simulatorBody).forEach(([k, val]) => {
                if (param.key === k) {
                    simulatorBody[k] = param.value;
                }
            });
        });
        getSimulateurValues(simulatorBody);
    }

    return simulatorValues && <RacSimulatorView typeSoins={typeSoins} getSimulateurValues={getSimulateurValues} onChange={handleChange}
        firstPrestation={simulatorValues.firstPrestationDTO} simulatorValues={simulatorValues} showTitle={showTitle !== false} />
}

export default RazSimulatorBlock