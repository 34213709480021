import React, { Component } from "react"
import DeathBenefitQuotation from "../../components/deathBenefitQuotation"
import { Row, Col } from 'react-bootstrap'
import Axios from "axios";
import ComplementaryQuotation from "../../components/complementaryQuotation/complementaryQuotation";
import ComplementarySended from "../../components/complementaryQuotation/complementarySended";
import ComplementaryFailed from "../../components/complementaryQuotation/complementaryFailed";
import LegalNoticeComplementary from "../../components/complementaryQuotation/legalNoticeComplementary";

class DeathBenefit extends Component {

  componentDidMount() {
/*    let URL = "http://localhost:8080/api/situation-familiales/actif"*/
    const URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/situation-familiales/actif`
    Axios.get(URL).then(result => {
      this.setState({ situationFamiliale: result.data });

    }).catch(()=>this.setState({ situationFamiliale:  [{id: 1, libelle:''},{ id:2, libelle:''}] }) )
  }

  constructor(props) {
    super(props);
    this.state = {
        situationFamiliale: [{id: 1, libelle:''},{ id:2, libelle:''}],
        status: 'start'
    };

    console.log('----props-----')
    console.log(props)
    console.log('----props-----')
}
    changeStatus = val =>{
        this.setState({status: val})
    }
  render() {
    return (
        <section className="quotation-body quotation-complementary">
            <div className="container-quotation">
                <div className="quotation-form col-xs-12">
                    <div>
                        <Col md={{ span: 10, offset: 1 }} xs={12}>
                            { this.state.status === 'start' &&
                            <DeathBenefitQuotation situationFamiliale={this.state.situationFamiliale} changeStatus={this.changeStatus}/>
                            }
                            { this.state.status === 'sended' &&
                            <ComplementarySended title={'PRÉVOYANCE - DEMANDE DE DEVIS MGC CAPITAL OBSÈQUES'} quotationform={this.props.title}/>
                            }
                            { this.state.status === 'failed' &&
                            <ComplementaryFailed quotationform={this.props.title}/>
                            }
                        </Col>
                        {/*<Col xs={{ span: 10, offset: 1 }}  md={{ span: 8, offset: 2 }} className="ptop p-bottom-50">
                            <p> Conformément à la loi « informatique et libertés » N°78-17 du 6 janvier 1978 modifiée, vous bénéficiez d’un droit d’accès,
                                de rectification et d’opposition aux informations vous concernant.
                                Ces droits peuvent être exercés à : MGC Service Adhérents, 2-4 place de l’Abbé Georges Hénocque, 75637 PARIS Cedex 13.
                            </p>
                        </Col>   */}
                        <LegalNoticeComplementary/>
                    </div>
                </div>
            </div>
    </section>
    )
  }
}

export default DeathBenefit
