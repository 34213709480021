import React from "react"
import CibleFormulePresentationTable from "./cibleFormulePresentationTable"
import CibleFormulePresentationOffer from "./cibleFormulePresentationOffer"
import CibleIndependantHospi from "../images/cible-independant-hospi.png"
import classNames from "classnames"

const CibleFormulePresentation = ({
  withOffer,
  codeCouleur,
  formulas,
  subClass,
  nbFormule,
  codeGamme,
  offerConditionsLink,
  offerConditionsText,
  offerImage,
  offerURL,
  tns,
  priceLink,
  alt,
 niveauAidesAuditivesDisponible
}) => {
  return (
    <div className={`cibleFormulePresentation ${subClass || ""}`}>
      <CibleFormulePresentationTable
        codeCouleur={codeCouleur}
        formulas={formulas}
        nbFormule={nbFormule}
        tns={tns}
        priceLink={priceLink}
        niveauAidesAuditivesDisponible={niveauAidesAuditivesDisponible}
      />
      {withOffer && (
        <CibleFormulePresentationOffer
          codeGamme={codeGamme}
          offerConditionsLink={offerConditionsLink}
          offerConditionsText={offerConditionsText}
          offerImage={offerImage}
          offerURL={offerURL}
          priceLink={priceLink}
          alt={alt}
        />
      )}
    </div>
  )
}

export default CibleFormulePresentation
