import React, { Component } from "react"
import { Tabs, Accordion, Tab, Card, Button } from "react-bootstrap"
import CibleFormulesGammeButton from "../../components/cibleFormuleGammeButton"
import RazSimulatorBlock from "./razSimulatorBlock"

class FourTabBlock extends Component {
  data
  media
  tabClass1 = ""
  tabClass2 = ""
  tabClass3 = ""
  tabClass4 = ""
  tabClass5 = ""
  tabClass6 = ""
  nombreBlocksInitialser

  componentWillMount() {
    this.data = this.props.data;
    this.media = this.props.media;
    this.nombreBlocksInitialser = this.data.nombreBlocksInitialser;
    if (this.data.titleTab1 && this.data.titleTab1.length < 23) this.tabClass1 = "one-lines "
    if (this.data.titleTab2 && this.data.titleTab2.length < 23) this.tabClass2 = "one-lines"
    if (this.data.titleTab3 && this.data.titleTab3.length < 23) this.tabClass3 = "one-lines"
    if (this.data.titleTab4 && this.data.titleTab4.length < 23) this.tabClass4 = "one-lines"
    if (this.data.titleTab5 && this.data.titleTab5.length < 23) this.tabClass5 = "one-lines"
    if (this.data.titleTab6 && this.data.titleTab6.length < 23) this.tabClass6 = "one-lines"

    this.tabClass1 = this.tabClass1 +   " width-" + this.nombreBlocksInitialser;
    this.tabClass2 = this.tabClass2 +  " width-" +  this.nombreBlocksInitialser;
    this.tabClass3 = this.tabClass3 +  " width-" +  this.nombreBlocksInitialser;
    this.tabClass4 = this.tabClass4 +  " width-" +  this.nombreBlocksInitialser;
    this.tabClass5 = this.tabClass5+  " width-" +  this.nombreBlocksInitialser;
    this.tabClass6 = this.tabClass6 + " width-" +  this.nombreBlocksInitialser;
  }

  constructor(props) {
    super(props)
    this.state = { iconClass: "iconThree fas fa-chevron-right" }
    this.card0 = React.createRef();
    this.card1 = React.createRef();
    this.card2 = React.createRef();
    this.card3 = React.createRef();
    this.card5 = React.createRef();
    this.card6 = React.createRef();
  }

  changeIconClass(id) {
    this.setState({ iconClass: id })
    if (this.state.iconClass === id) {
      this.setState({ iconClass: null })
    }
    setTimeout(()=>this.test(id), 450)
  }
  test(id){
    switch (id) {
      case 0:
        this.card0.current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
        break;
      case 1:
        this.card1.current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
        break;
      case 2:
        this.card2.current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
        break;
      case 3:
        this.card3.current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
        break;
        case 5:
          this.card5.current.scrollIntoView({
            behavior: 'instant',
            block: 'start',
          });
          break;
        case 6:
          this.card6.current.scrollIntoView({
            behavior: 'instant',
            block: 'start',
          });
          break;
    }
  }

  render() {
    return (
      <div>
        <section className="formuleModalities">
          <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
            <Tab
              eventKey="tab1"
              title={this.data.titleTab1}
              tabClassName={this.tabClass1}
            >
              <div
                className="formuleModalitiesContent"
                dangerouslySetInnerHTML={{ __html: this.data.textTab1 }}
              />
              <div className="container">
              {this.data.media1 !== undefined && this.data.media1 !== null && this.data.media1.image !== null ? (
                  <CibleFormulesGammeButton
                    linkButton={this.media[this.data.media1.image.id]}
                    textButton={this.data.media1Text}
                  />
                ) : (
                    <></>
                  )}
              </div>
            </Tab>
            <Tab
              eventKey="tab2"
              title={this.data.titleTab2}
              tabClassName={this.tabClass2}
            >
              <div
                className="formuleModalitiesContent"
                dangerouslySetInnerHTML={{ __html: this.data.textTab2 }}
              />
            </Tab>
           {
                (this.nombreBlocksInitialser) >= 3 &&
                <Tab
                eventKey="tab3"
                title={this.data.titleTab3}
                tabClassName={this.tabClass3}
              >
                {
                  this.data.mgcgestionFormuleId == null ?
                  (<div
                    className="formuleModalitiesContent"
                    dangerouslySetInnerHTML={{ __html: this.data.textTab3 }}
                  />) : <div className="mt-5"> <RazSimulatorBlock data={{mgcgestionFormulaId: this.data.mgcgestionFormuleId}} showTitle={false} /> </div>
                  
                }
              </Tab>
           }
           {
             (this.nombreBlocksInitialser >= 4) &&
                <Tab
                eventKey="tab4"
                title={this.data.titleTab4}
                tabClassName={this.tabClass4}
              >
                <div
                  className="formuleModalitiesContent"
                  dangerouslySetInnerHTML={{ __html: this.data.textTab4 }}
                />
              </Tab>
           }
            {
             ( this.nombreBlocksInitialser >= 5) &&
                  <Tab
                  eventKey="tab5"
                  title={this.data.titleTab5}
                  tabClassName={this.tabClass5}
                >
                  <div
                    className="formuleModalitiesContent"
                    dangerouslySetInnerHTML={{ __html: this.data.textTab5 }}
                  />
                </Tab>
            }
             {  ( this.nombreBlocksInitialser >= 6) &&
              <Tab
                eventKey="tab6"
                title={this.data.titleTab6}
                tabClassName={this.tabClass6}
              >
                <div
                  className="formuleModalitiesContent"
                  dangerouslySetInnerHTML={{ __html: this.data.textTab6 }}
                />
              </Tab>
              }
          </Tabs>
        </section>
        <div id="formuleAccordionMobile" className="formuleAccordion">
          <Accordion>
             <Card id="card-1"  ref={this.card0}>
              <Card.Header className="card-header" >
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  className="formuleModalitiesMobile"
                  onClick={() => this.changeIconClass(0)}
                  /*href='#card-1'*/
                >
                  {this.data.titleTab1}{" "}
                  <i
                    className={
                      this.state.iconClass === 0
                        ? "iconOne fas fa-chevron-down"
                        : "iconThree fas fa-chevron-right"
                    }
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.data.textTab1 }}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="card-2" ref={this.card1}>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="1"
                  className="formuleModalitiesMobile"
                  onClick={() => this.changeIconClass(1)}
                  /*href='#card-2'*/
                >
                  {this.data.titleTab2}{" "}
                  <i
                    className={
                      this.state.iconClass === 1
                        ? "iconOne fas fa-chevron-down"
                        : "iconThree fas fa-chevron-right"
                    }
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.data.textTab2 }}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
           {
              (this.nombreBlocksInitialser >= 3) && <Card id="card-3" ref={this.card2}>
              <Card.Header >
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="2"
                  className="formuleModalitiesMobile"
                  onClick={() => this.changeIconClass(2)}
                  /*href='#card-3'*/
                >
                  {this.data.titleTab3}{" "}
                  <i
                    className={
                      this.state.iconClass === 2
                        ? "iconOne fas fa-chevron-down"
                        : "iconThree fas fa-chevron-right"
                    }
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                    {
                    this.data.mgcgestionFormuleId == null ?
                    (<div
                      dangerouslySetInnerHTML={{ __html: this.data.textTab3 }}
                    />) : <div className="mt-5"> <RazSimulatorBlock data={{mgcgestionFormulaId: this.data.mgcgestionFormuleId}} showTitle={false} /> </div>
                    
                  }
                </Card.Body>
              </Accordion.Collapse>
            </Card>
           }
            {
              (this.nombreBlocksInitialser >= 4) && <Card id="card-4" ref={this.card3}>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="3"
                  className="formuleModalitiesMobile"
                  onClick={() => this.changeIconClass(3)}
                 /* href='#card-4'*/
                >
                  {this.data.titleTab4}{" "}
                  <i
                    className={
                      this.state.iconClass === 3
                        ? "iconOne fas fa-chevron-down"
                        : "iconThree fas fa-chevron-right"
                    }
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.data.textTab4 }}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            }
            {
              (this.nombreBlocksInitialser >= 5) && <Card id="card-5" ref={this.card4}>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="4"
                  className="formuleModalitiesMobile"
                  onClick={() => this.changeIconClass(4)}
                 /* href='#card-4'*/
                >
                  {this.data.titleTab5}{" "}
                  <i
                    className={
                      this.state.iconClass === 4
                        ? "iconOne fas fa-chevron-down"
                        : "iconThree fas fa-chevron-right"
                    }
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.data.textTab5 }}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            }
            {
              (this.nombreBlocksInitialser >= 6) &&
              <Card id="card-6" ref={this.card5}>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="5"
                  className="formuleModalitiesMobile"
                  onClick={() => this.changeIconClass(5)}
                 /* href='#card-4'*/
                >
                  {this.data.titleTab6}{" "}
                  <i
                    className={
                      this.state.iconClass === 5
                        ? "iconOne fas fa-chevron-down"
                        : "iconThree fas fa-chevron-right"
                    }
                  />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.data.textTab6 }}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            }
          </Accordion>
        </div>
      </div>
    )
  }
}

export default FourTabBlock
