import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./actuHome.css"
import { ATInternetTagService } from '../services/global/ATInternetService';

const actuHome = () => (
  <StaticQuery
    query={graphql`
        query actuHomeQuery {
            jhipster {
              highlightedActualitiesRange(actuPerPage:2,currentPage:0) {
                id
                title
                description
                url
              }
            }
          }
    `}
    render={data => (
      data.jhipster.highlightedActualitiesRange[0] || data.jhipster.highlightedActualitiesRange[1] ?
        (
          <div className={"actuHome"}>
            {data.jhipster.highlightedActualitiesRange[0] && (
              <a href={'actualite/' + data.jhipster.highlightedActualitiesRange[0].url} onClick={() => ATInternetTagService.sendNavClick({click:data.jhipster.highlightedActualitiesRange[0].title,click_chapter2:'actualites'})}>
                <div>
                  <p className="actuTitle">{data.jhipster.highlightedActualitiesRange[0].title}</p>
                  <p>{data.jhipster.highlightedActualitiesRange[0].description}</p>
                  <span className="underline">En savoir plus</span>
                </div>
              </a>
            )}
            {data.jhipster.highlightedActualitiesRange[1] &&
              <a href={'actualite/' + data.jhipster.highlightedActualitiesRange[1].url} onClick={() => ATInternetTagService.sendNavClick({click:data.jhipster.highlightedActualitiesRange[1].title,click_chapter2:'actualites'})}>
                <div >
                  <p className={"actuTitle"}>{data.jhipster.highlightedActualitiesRange[1].title}</p>
                  <p>{data.jhipster.highlightedActualitiesRange[1].description}</p>
                  <span className="underline">En savoir plus</span>
                </div>
              </a>
            }

          </div>)
        : null
    )}
  />
)

export default actuHome;
