import * as Yup from "yup"

const ComplementaryQuotationValidation = Yup.object().shape({
  nom: Yup.string().trim().required("nom is required"),
  prenom: Yup.string().trim().required("prenom is required"),
  dateNaissance: Yup.string()
    .test(
      "test-majeur",
      "Il n'est pas possible d'adhérer avant 18 ans",
      function(value) {
        return checkAge(value)
      }
    )
    .required("Il n'est pas possible d'adhérer avant 18 ans"),
  regimeAffiliation: Yup.string()
    .test("test-regimeAffiliation", "regimeAffiliation is required", function(
      value
    ) {
      return checkSelect(value)
    })
    .required("regimeAffiliationConjoint is required"),
  adherentMGC: Yup.string()
    .matches(/(true|false)/, { excludeEmptyString: false })
    .required("adherentMGC is required"),
  civilite: Yup.string()
    .matches(/(Monsieur|Madame)/, { excludeEmptyString: false })
    .required("civilite is required"),
  adresseLieuDit: Yup.string(),
  appartement: Yup.string(),
  batiment: Yup.string(),
  adresseNumero: Yup.string(),
  adresseNumeroLatin: Yup.string(),
  email: Yup.string()
    .email()
    .required("email is required"),
  adresseTypeVoie: Yup.string().required("adresseTypeVoie is required"),
  adresseVoie: Yup.string().required("adresseVoie is required"),
  adresseCodePostal: Yup.string().required("adresseCodePostal is required"),
  adresseVille: Yup.string().required("adresseVille is required"),
  adresseComplementAlpha: Yup.string().matches(/^[A-Z]{1}$/, {
    excludeEmptyString: true,
  }),
  telephone1: Yup.string()
    .matches(/^(\\+33|0|0033)[0-9]{9}$/, { excludeEmptyString: true })
    .required("telephone1 is required"),
  telephone2: Yup.string().matches(/^(\\+33|0|0033)[0-9]{9}$/, {
    excludeEmptyString: true,
  }),
  depassementHonoraires: Yup.string()
    .matches(/(true|false)/, { excludeEmptyString: false })
    .required("depassementHonoraires is required"),
  optique: Yup.string()
    .matches(/(true|false)/, { excludeEmptyString: false })
    .required("depassementHonoraires is required"),
  dentaire: Yup.string()
    .matches(/(true|false)/, { excludeEmptyString: false })
    .required("depassementHonoraires is required"),
  hospitalisation: Yup.string()
    .matches(/(true|false)/, { excludeEmptyString: false })
    .required("depassementHonoraires is required"),
  autres: Yup.string()
    .matches(/(true|false)/, { excludeEmptyString: false })
    .required("depassementHonoraires is required"),
    couvertMutuelle: Yup.string().matches(/(true)/, { excludeEmptyString: false })
        .required("couvertMutuelle is required"),
  //Conjoint
  conjointChecked: Yup.boolean(),
  civiliteConjoint: Yup.string().when("conjointChecked", {
    is: true,
    then: Yup.string()
      .matches(/(Monsieur|Madame)/, { excludeEmptyString: false })
      .required("civilite is required"),
    otherwise: Yup.string().notRequired(),
  }),
  nomConjoint: Yup.string().when("conjointChecked", {
    is: true,
    then: Yup.string().required("nomConjoint is required"),
    otherwise: Yup.string().notRequired(),
  }),
  prenomConjoint: Yup.string().when("conjointChecked", {
    is: true,
    then: Yup.string().required("prenomConjoint is required"),
    otherwise: Yup.string().notRequired(),
  }),
  dateNaissanceConjoint: Yup.string().when("conjointChecked", {
    is: true,
    then: Yup.string()
      .test(
        "test-majeur",
        "Il n'est pas possible d'adhérer avant 18 ans",
        function(value) {
          return checkAge(value)
        }
      )
      .required("Il n'est pas possible d'adhérer avant 18 ans"),
    otherwise: Yup.string().notRequired(),
  }),
  regimeAffiliationConjoint: Yup.string().when("conjointChecked", {
    is: true,
    then: Yup.string()
      .test(
        "test-regimeAffiliationConjoint",
        "regimeAffiliationConjoint is required",
        function(value) {
          return checkSelect(value)
        }
      )
      .required("regimeAffiliationConjoint is required"),
    otherwise: Yup.string().notRequired(),
  }),
  /*  depassementHonoraires: Yup.string.matches(/(true|false)/, {excludeEmptyString: false}).required("depassementHonoraires is required"),*/
})
export default ComplementaryQuotationValidation

function checkSelect(val) {
  if (val !== "null" && val) return val
  return false
}

function checkAge(birthday) {
  let age = Date.parse(birthday)
  let ageDifMs = Date.now() - age
  let ageDate = new Date(ageDifMs)

  if (ageDate.getUTCFullYear() - 1970 >= 18) {
    return birthday
  } else {
    return false
  }
}
