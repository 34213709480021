import React, { Component } from "react"
import ActuHome from "../../components/actuHome"
import "./textWithActualitiesBlock.css"
import AbstractMedia from "../../components/media/AbstractMedia"

class TextWithActualities extends Component {
  data
  media = {}

  componentWillMount() {
    this.data = this.props.data
    this.media = this.props.media
  }
  render() {
    return (
      <section className="homePresentation">
        <div className="container">
          <div className="row">
            <div className="col-md-8 h4Pink">
              <div dangerouslySetInnerHTML={{ __html: this.data.text }} />
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <a href="/actualites/">
                    <p className="tac">Actualités</p>
                  </a>
                  <ActuHome />
                </div>
              </div>
              {this.data.media ? (
                <div className="card">
                  <a href={this.data.takeAdvantageLink}>
                    <AbstractMedia
                      abstractMedia={this.data.media}
                      media={this.media}
                      className="offre-promo"
                      alt={this.data.alt}
                    />
                  </a>
                  {this.data.offerLink ? (
                    <div className="card-text m-top-10">
                      <a href={this.data.offerLink}>
                        Voir les conditions de l’offre
                      </a>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TextWithActualities
