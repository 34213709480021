import React, { Component } from "react"
import ComplementaryQuotation from "../../components/complementaryQuotation/complementaryQuotation"
import { Row, Col } from "react-bootstrap"
import Axios from "axios"
import ComplementarySended from "../../components/complementaryQuotation/complementarySended";
import ComplementaryFailed from "../../components/complementaryQuotation/complementaryFailed";
import LegalNoticeComplementary from "../../components/complementaryQuotation/legalNoticeComplementary";

class ComplementaryQuotationBlock extends Component {
  state = {
    regimeAssurance: [1, 2],
    situationProfessionelle: [1, 2],
    status: 'start'
  }
    componentDidMount() {

/*        let URL = "http://localhost:8080/api/public/regime-assurances/actif"*/
        let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/regime-assurances/actif`
         Axios.get(URL)
          .then(result => {
            this.setState({ regimeAssurance: result })
            this.setState({status: "start"})
          })
          .then(() => {
/*            URL = "http://localhost:8080/api/public/situation-professionnelles/actives"*/
            URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/situation-professionnelles/actives`
            Axios.get(URL).then(result => {
              let situationPro = []
              for (let o = 0; o < this.state.regimeAssurance.data.length; o++) {
                situationPro[this.state.regimeAssurance.data[o].code] = []
                for (let i = 0; i < result.data.length; i++) {
                  for (let j = 0; j < result.data[i].regimeAssurances.length; j++) {
                    if (
                      result.data[i].regimeAssurances[j].code ==
                      this.state.regimeAssurance.data[o].id
                    ) {
                      result.data[i].id = result.data[i].code
                      situationPro[this.state.regimeAssurance.data[o].code].push(
                        result.data[i]
                      )
                    }
                  }
                }
              }
              this.setState({ situationProfessionelle: situationPro })
              this.setState({status: "start"})
            })
          })
      }

      changeStatus = val =>{
      this.setState({status: val})
      }
      constructor(props) {
        super(props)

      }

  render() {
    return (
      <section className="quotation-body quotation-complementary">
        <div className="container-quotation">
          <div className="quotation-form col-xs-12">
              <Col md={{ span: 10, offset: 1 }} xs={12}>
                { this.state.status === 'start' &&
                <ComplementaryQuotation regimeAssurance={this.state.regimeAssurance} changeStatus={this.changeStatus}/>
                }
                { this.state.status === 'sended' &&
                <ComplementarySended title={'DEVIS SURCOMPLÉMENTAIRE'}/>
                }
                { this.state.status === 'failed' &&
                <ComplementaryFailed/>
                }
              </Col>
              {/*<Col xs={{ span: 10, offset: 1 }}  md={{ span: 8, offset: 2 }} className="ptop m-bottom-50">
                <p>
                  Les informations demandées sont obligatoires pour
                  l’établissement d’un devis complémentaire santé
                  (Sur-complémentaire) personnalisé, qui à défaut ne peut être
                  effectué. Conformément à la loi « informatique et libertés »
                  N°78-17 du 6 janvier 1978 modifiée, vous bénéficiez d’un droit
                  d’accès, de rectification et d’opposition aux informations
                  vous concernant. Ces droits peuvent être exercés à : MGC
                  Service Devis, 2-4 place de l’Abbé Georges Hénocque, 75637
                  PARIS cedex 13.
                </p>
              </Col>*/}
              <LegalNoticeComplementary/>
          </div>
        </div>
      </section>
    )
  }
}

export default ComplementaryQuotationBlock
