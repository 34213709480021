import React, { Component } from "react"
import "./threeBlockNumber.css"

class ThreeBlockNumber extends Component {
    data;
    componentWillMount() {
        this.data = this.props.data;
    }
    render() {
        return (
            <section className="homeHistory">
                <div className="container ptl pbl">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="tac">{this.data.title}</h3>
                        </div>
                        <div className="col-12">
                            <p>{this.data.subtitle}</p>
                        </div>
                    </div>
                    <div className="homeHistoryNumbers">
                        <div className="row">
                            <div className="col-md-4">
                                <p className="number">{this.data.titleBlock1}</p>
                                <p>{this.data.subtitleBlock1}</p>
                            </div>
                            <div className="col-md-4">
                                <p className="number">{this.data.titleBlock2}</p>
                                <p>{this.data.subtitleBlock2}</p>
                            </div>
                            <div className="col-md-4">
                                <p className="number">{this.data.titleBlock3}</p>
                                <p>{this.data.subtitleBlock3}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ThreeBlockNumber