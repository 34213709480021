import React from "react"
import { ATInternetTagService } from './../services/global/ATInternetService';

const  ButtonFormulaHeaderBlock = ({aelLinkBtn, quotLinkBtn }) => {
    let content = ''
    if(aelLinkBtn && quotLinkBtn){
        content =
            <section className="homeButtons formula-header-block-btn">
            <div className="container">
                <div className="homeButtonsFlex">
                    <a href={quotLinkBtn} className="buttonDevis" onClick={() => ATInternetTagService.sendNavClick({click:'demande_de_devis'})}>Demande de devis</a>
                    <a href={aelLinkBtn} className="buttonAdhesion" onClick={() => ATInternetTagService.sendNavClick({click:'adhesion'})}>Adhésion</a>
                </div>
            </div>
        </section>
    }else if(aelLinkBtn){
        content =    <section className="homeButtons formula-header-block-btn">
            <div className="container">
                <div className="homeButtonsFlex">
                    <a href={aelLinkBtn} className="buttonAdhesion" onClick={() => ATInternetTagService.sendNavClick({click:'adhesion'})}>Adhésion</a>
                </div>
            </div>
        </section>
    }
    else if(quotLinkBtn){
        content =    <section className="homeButtons formula-header-block-btn">
            <div className="container">
                <div className="homeButtonsFlex">
                    <a href={quotLinkBtn} className="buttonDevis" onClick={() => ATInternetTagService.sendNavClick({click:'demande_de_devis'})}>Demande de devis</a>
            </div>
            </div>
        </section>
    }
        return(
           content
        )
}
export default ButtonFormulaHeaderBlock