import React, { Component } from "react"
import OrizenQuotation from "../../components/orizenQuotation"
import { Col } from 'react-bootstrap'
import OrizenComplementarySended from "../../components/orizenComplementarySended";
import ComplementaryFailed from "../../components/complementaryQuotation/complementaryFailed";

class Orizen extends Component {

  componentDidMount() {
  }

  constructor(props) {
    super(props);
    this.state = {
        situationFamiliale: [{id: 1, libelle:''},{ id:2, libelle:''}],
        status: 'start'
    };
}
    changeStatus = val =>{
        this.setState({status: val})
    }
  render() {
    return (
        <section className="quotation-body quotation-complementary">
            <div className="container-quotation">
                <div className="quotation-form col-xs-12">
                    <div>
                        <Col md={{ span: 10, offset: 1 }} xs={12}>
                            { this.state.status === 'start' &&
                                <OrizenQuotation situationFamiliale={this.state.situationFamiliale} changeStatus={this.changeStatus}/>
                            }
                            { this.state.status === 'sended' &&
                            <OrizenComplementarySended title={'VOTRE DEMANDE DE RAPPEL A ÉTÉ PRISE EN COMPTE !'} quotationform={this.props.title}/>
                            }
                            { this.state.status === 'failed' &&
                            <ComplementaryFailed quotationform={this.props.title}/>
                            }
                        </Col>
                    </div>
                </div>
            </div>
    </section>
    )
  }
}

export default Orizen
