export const checkSMSCompatible = value => {
  return (
    /^\+336[0-9]*$/.test(value) ||
    /^\+337[0-9]*$/.test(value) ||
    /^06[0-9]*$/.test(value) ||
    /^07[0-9]*$/.test(value) ||
    /^06( [0-9]{2}){4}$/.test(value) ||
    /^07(.[0-9]{2}){4}$/.test(value) ||
    /^06(.[0-9]{2}){4}$/.test(value)
  )
}

export const checkTelephone = value => {
  return (
    /^\+33[0-9]{9}$/.test(value) ||
    /^0[0-9]( ?[0-9]{2}){4}$/.test(value) ||
    /^0[0-9](.[0-9]{2}){4}$/.test(value)
  )
}

export const checkCleNss = nss => {
  var nssArr = nss.split(" ")
  var nssDept = nssArr[3]
  var key = nssArr[6]
  // cas pour la corse
  if (nssDept === "2A") {
    nssDept = 19
  }
  if (nssDept === "2B") {
    nssDept = 18
  }
  var nir = nssArr.slice(0, 3).join("") + nssDept + nssArr.slice(4, 6).join("")

  var generatedKey = 97 - (parseInt(nir, 10) % 97)
  return generatedKey === parseInt(key, 10)
}

export const checkCoherenceNSS = (nss, dateNaissance, civilite) => {
  if (!nss || !dateNaissance || !civilite) {
    return false
  }

  var nssArr = nss.split(" ")
  var sNss = parseInt(nssArr[0])
  var anNss = parseInt(nssArr[1])
  var moisNss = parseInt(nssArr[2])

  if (civilite === "monsieur" && sNss !== 1 && sNss !== 3 && sNss !== 7) {
    return false
  }
  if (civilite === "madame" && sNss !== 2 && sNss !== 3 && sNss !== 7) {
    return false
  }
  if (dateNaissance.getMonth() + 1 !== moisNss) {
    return false
  }
  if (
    parseInt(
      dateNaissance
        .getFullYear()
        .toString()
        .substr(-2)
    ) !== anNss
  ) {
    return false
  }
  return checkCleNss(nss)
}

export const checkAge = age => {
  let today = new Date()
  let year = today.getFullYear()
  let ageMin = new Date(year - 18, today.getMonth(), today.getDate())
  if (ageMin > age) {
    return age
  } else {
    return false
  }
}

export const checkNumeroMutuelle = numeroString => {
  if (numeroString) {
    numeroString = numeroString.split(' ').join('');
    //Test que le siren ne contient pas autre chause que des chiffres
    //Le + renvoie le nombre S'il n'y a QUE des chiffres dans la chaine, NAN sinon. Le parseInt/IsNaN ne fonctionne pas avec les cas du genre 12ab46 (ça considère que ça vaut 12)
    if (isNaN(+numeroString)) {
      return false
    }
    return (numeroString.length == 9 || numeroString.length == 14)
  } else {
    return false
  }
}
