import React, { Component } from "react";
import { Badge } from 'react-bootstrap';

import cibleCheminot from "../../images/people-cheminot.png";
import cibleCheminotRetraite from "../../images/cheminotRetraite.png";
import cibleParticulier from "../../images/people-particulier.png";
import cibleParticulierSenior from "../../images/cible-retraite.png"
import cibleAgent from "../../images/people-agent.png";
import cibleIndependantTPE from "../../images/people-tpe2023.png";
import cibleIndependant from "../../images/people-entreprise.png";
import syntec from "../../images/syntec.png";
import cibleParrainage from "../../images/Cheques-cadeaux-parrainage.png";
import IconUmbrella from '../../images/icon-umbrella.png'
import "./cibleBlock.css"
import { ATInternetTagService } from './../../services/global/ATInternetService';

var classNames = require('classnames');

class CibleBlock extends Component {
    data;
    cibleHeaderDescriptionClass;
    cibleHeaderLinkClass;
    cibleHeaderDescriptionImageClass;
    cibleImage;
    cibleSeconProductMobile
    style= {} ;

    // Pas appelé mais on garde le code au cas où
    AtInternetTagPage() {
        let url = ATInternetTagService.getPageUrl()
        if (!url) {
            return;
        }
        let chapter = url
        if (url.indexOf('cheminot') !== -1) {
            chapter = 'cheminot'
        } else if (url === 'senior' || url === 'particulier') {
            chapter = 'particulier'
        } else if (url === 'agent-territorial' || url === 'collectivite-territoriale') {
            chapter = 'agent_territorial'
        } else if (url === 'professionnel-independant' || url === 'entreprise' || url === 'entreprise-syntec') {
            chapter = 'independant'
        }
        ATInternetTagService.sendPage({page:url,page_chapter1:chapter});
    }
    componentWillMount() {
        this.data = this.props.data;
        // pas appelée parce que appelée dans SimpleTemplate
        //this.AtInternetTagPage()
        switch (this.props.data.color) {
            case 'PARTICULIER':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'particulier'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'particulier'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'particulier'])
                this.cibleImage = cibleParticulier;
                this.cibleSeconProductMobile ="secondOfferMobile particulier"
                break;
            case 'PARTICULIER_SENIOR':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'particulier'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'particulier'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'particulier'])
                this.cibleImage = cibleParticulierSenior;
                this.cibleSeconProductMobile ="secondOfferMobile particulier"
                break;
            case 'AGENT_TERRITORIAL':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'agent'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'agent'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'agent'])
                this.cibleImage = cibleAgent;
                this.cibleSeconProductMobile ="secondOfferMobile agent"
                break;
            case 'INDEPENDANT_ENTREPRISE':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'independant'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'independant'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'independant'])
                this.cibleImage = cibleIndependant;
                this.cibleSeconProductMobile ="secondOfferMobile independant"
                break;
            case 'INDEPENDANT_ENTREPRISE_TPE':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'independant'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'independant'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'independant'])
                this.cibleImage = cibleIndependantTPE;
                this.cibleSeconProductMobile ="secondOfferMobile independant"
                break;
            case 'SYNTEC':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'independant'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'independant'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'independant'])
                this.cibleImage = syntec;
                this.cibleSeconProductMobile ="secondOfferMobile independant"
                break;
            case 'LANDING_PARRAINAGE':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'landing-parrainage'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'landing-parrainage'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'landing-parrainage'])
                this.cibleImage = cibleParrainage;
                this.cibleSeconProductMobile ="secondOfferMobile landing-parrainage"
                break;
            case 'CHEMINOT_RETRAITE':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'cheminot'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'cheminot'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'cheminot'])
                this.cibleImage = cibleCheminotRetraite;
                this.cibleSeconProductMobile ="secondOfferMobile cheminot"
                break;
            default:
            case 'CHEMINOT':
                this.cibleHeaderDescriptionClass = classNames(['cibleHeaderDescription', 'cheminot'])
                this.cibleHeaderLinkClass = classNames(['cibleHeaderLink', 'cheminot'])
                this.cibleHeaderDescriptionImageClass = classNames(['cibleHeaderDescriptionImage', 'cheminot'])
                this.cibleImage = cibleCheminot;
                this.cibleSeconProductMobile ="secondOfferMobile cheminot"
                break;
        }
        if(this.props.landing){
            this.style = {
                marginTop: '30px',
                marginLeft: '2vw',
                marginRight: 'auto',
                float: 'none'
            };
        }else {
            this.style = {
                marginTop: '30px',
                marginLeft: 'auto',
                marginRight: 'auto',
                float: 'none'
            };
        }
        this.cibleHeaderDescriptionContainerClasses = classNames("cibleHeaderDescriptionContainer",{
            "wo-second-product" : (!this.props.landing && (this.data.secondProduct === false || !this.data.secondProduct)),
            "with-landing" : this.props.landing
        })
    }



    render() {
        return (
            <>
            <section className="cibleHeader">
                <div className={this.cibleHeaderDescriptionClass}  style={(this.data.secondProduct === false || !this.data.secondProduct) ? {width: '100%'} : {}}>
                    <div className={this.cibleHeaderDescriptionContainerClasses}>
                    <div className="d-flex align-items-center">
                        <h1 className="cibleHeaderDescriptionTitle">{this.data.titleLeft}</h1>
                        {this.data.tag && <Badge pill variant="light formulePresentationTag">{this.data.tag}</Badge>}
                    </div>
                        <h2><p className="cibleHeaderDescriptionText"><div dangerouslySetInnerHTML={{ __html: this.data.textLeft }} /></p></h2>
                        <div className="cibleHeaderDescriptionFlex">
                            <div className="cibleHeaderDescriptionButton">
                                {this.data.linkQuot && <a href={this.data.linkQuot} className="buttonDevis" 
                                    onClick={() => ATInternetTagService.sendNavClick({click:'demande_de_devis'})}
                                    >Demande de devis</a>}
                                {this.data.linkAel && <a href={this.data.linkAel} className="buttonAdhesion" 
                                    onClick={() => ATInternetTagService.sendNavClick({click:'adhesion'})}
                                    >Adhésion</a>}
                            </div>
                            <img src={this.cibleImage} alt="" className={this.cibleHeaderDescriptionImageClass}/>
                        </div>
                    </div>
                </div>
                {this.data.secondProduct  === true && <div className={this.cibleHeaderLinkClass}>
                    <div className="cibleHeaderLinkContainer" >
                        <img src={IconUmbrella} className="cibleHeaderLinkImage" alt=""/>
                        <h3 className="cibleHeaderLinkTitle" dangerouslySetInnerHTML={{__html: this.data.titleRight}} />
                        <p className="cibleHeaderLinkText"><div dangerouslySetInnerHTML={{ __html: this.data.textRight }} /></p>
                        <form action={this.data.knowMore}>
                            <button type="submit" className="cibleHeaderLinkButton" onClick={() => ATInternetTagService.sendNavClick({click:'en_savoir_plus'})}>En savoir plus</button>
                        </form>
                    </div>
                </div>}
            </section>
        {this.data.secondProduct && (
            <a href={this.data.knowMore}>
            <div
                className={
                    "secondOfferMobile-contain no-button"
                }
            >
                <div className={this.cibleSeconProductMobile}>
                    <img
                        src={IconUmbrella}
                        className="formuleHeaderLinkImage"
                        alt={this.data.titleRight}
                    />
                    <h3 className="formuleHeaderLinkTitle" dangerouslySetInnerHTML={{__html: this.data.titleRight}} />
                </div>
            </div>
            </a>
        )}
        </>
        );
    }
}

export default CibleBlock
