import React, { Component } from "react"

class FourElementPinkBlock extends Component {
    data;
    componentWillMount() {
        this.data = this.props.data;
    }

    render() {
        const blocks = [
            {
                id: 1,
                text: this.data.text1,
                subtitle: this.data.subtitle1,
                url: this.data.url1,
            },
            {
                id: 2,
                text: this.data.text2,
                subtitle: this.data.subtitle2,
                url: this.data.url2,
            },
            {
                id: 3,
                text: this.data.text3,
                subtitle: this.data.subtitle3,
                url: this.data.url3,
            },
            {
                id: 4,
                text: this.data.text4,
                subtitle: this.data.subtitle4,
                url: this.data.url4,
            }
        ]

        return <section className="homeServices">
            <div className="container ptl pbl">
                <div className="row">
                    <div className="col">
                        <h3>{this.data.title}</h3>
                    </div>
                </div>
                <div className="row">
                    {blocks.map(block => (
                        <div key={`four-element-pink-${block.id}`} className="col-sm-6 col-md-3">
                            {block.url && block.url.length > 0 ? (
                                <a href={block.url}>
                                    <i className="fas fa-check"></i>
                                    <h4><div dangerouslySetInnerHTML={{ __html: block.text }} /></h4>
                                    <p><div dangerouslySetInnerHTML={{ __html: block.subtitle }} /></p>
                                </a>
                            ) : (
                                <div>
                                    <i className="fas fa-check"></i>
                                    <h4><div dangerouslySetInnerHTML={{ __html: block.text }} /></h4>
                                    <div className="mt-2" dangerouslySetInnerHTML={{ __html: block.subtitle }} ></div>
                                </div>
                            )}
                     </div>
                    ))}
                    {/* <div className="col-sm-6 col-md-3">
                        <i className="fas fa-check"></i>
                        <h4>{this.data.text1}</h4>
                        <p>{this.data.subtitle1}</p>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <i className="fas fa-check"></i>
                        <h4>{this.data.text2}</h4>
                        <p>{this.data.subtitle2}</p>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <i className="fas fa-check"></i>
                        <h4>{this.data.text3}</h4>
                        <p>{this.data.subtitle3}</p>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <i className="fas fa-check"></i>
                        <h4>{this.data.text4}</h4>
                        <p>{this.data.subtitle4}</p>
                    </div> */}
                </div>
            </div>
        </section>
    }
}

export default FourElementPinkBlock
