import Axios from "axios"
import {Field, Formik} from "formik"
import React from "react"
import {Col, Container, Row} from "react-bootstrap"
import MgcRadioToggleButton from "../mgcRadioToggleButton"
import MgcRadioToggleButtonGroup from "../mgcRadioToggleButtonGroup"
import MgcTextField from "../mgcTextField"
import QuotationAdresse from "../quotationAdresse"
import ComplementaryQuotationPerson from "./complementaryQuotationPerson"
import ComplementaryQuotationRequirements from "./complementaryQuotationRequirements"
import ComplementaryQuotationValidation from "./complementaryQuotationValidation"
import Alert from "react-bootstrap/Alert"
import {MgcgestionTokenService} from "../../services/mgcgestionTokenService";
import {ATInternetTagService} from "../../services/global/ATInternetService";

class ComplementatyQuotation extends React.Component {

    state = {
        regimeAssurance: this.props.regimeAssurance || [{id: 99, libelle: ""}],
        numeroLatinList : []
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.regimeAssurance &&
            this.props.regimeAssurance !== prevProps.regimeAssurance
        ) {
            this.props.regimeAssurance.data.forEach(e => {
                e.id = e.libelle
            })
            this.setState({regimeAssurance: this.props.regimeAssurance})
        }
    }
    componentDidMount() {
        let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/numero-latins`
/*        URL = "http://localhost:8080/api/public/numero-latins"*/
        Axios.get(URL).then(result => {
            this.setState({numeroLatinList : result.data })
        })
    }
    constructor(props) {
        super(props)
        this.changeStatus = this.props.changeStatus
    }

    render() {
        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={ComplementaryQuotationValidation}
                    onSubmit={(values, actions) => {
                        sendMail(values, this.changeStatus, this.state.numeroLatinList)
                        actions.setSubmitting(false)
                    }}
                    render={({
                                 isValid,
                                 setFieldValue,
                                 touched,
                                 values,
                                 errors,
                                 handleSubmit,
                                 dirty,
                                 handleBlur,
                             }) => (
                        <form onSubmit={handleSubmit}>
                            <Container className="quotation mtop">
                                <Row>
                                    <Col xs={{span: 11, offset: 1}}>
                                        <h4>Adhérent principal</h4>
                                    </Col>
                                </Row>
                                <ComplementaryQuotationPerson
                                    tabRegimeAffiliation={this.state.regimeAssurance}
                                    errors={errors}
                                    dirty={dirty}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    conjoint={false}
                                    touched={touched}
                                />
                                <Row className="question-y-n">
                                    <Col xs={{span: 11, offset: 1}}>
                    <span>
                      Je suis adhérent ou ancien adhérent de la mutuelle MGC ?
                    </span>
                                    </Col>
                                    <Col xs={12} className="quotation-radio">
                                        <MgcRadioToggleButtonGroup
                                            id="adherentMGC"
                                            error={errors.adherentMGC}
                                            touched={touched.adherentMGC}
                                            value={values.adherentMGC}
                                            dirty={true}
                                        >
                                            <Field
                                                component={MgcRadioToggleButton}
                                                name="adherentMGC"
                                                id="true"
                                                label={"Oui"}
                                            />
                                            <Field
                                                component={MgcRadioToggleButton}
                                                name="adherentMGC"
                                                id="false"
                                                label={"Non"}
                                            />
                                        </MgcRadioToggleButtonGroup>
                                    </Col>
                                </Row>
                                {values.couvertMutuelle === "false" ? (
                                    <Row className="question-y-n">
                                        <Col xs={{span: 10, offset: 1}}>
                                            <Alert variant="danger">
                                                Votre demande de devis en ligne ne peut aboutir car
                                                notre offre de surcomplémentaire santé Adimut est
                                                réservée aux bénéficiaires d'un contrat de
                                                complémentaire santé obligatoire.
                                            </Alert>
                                        </Col>
                                    </Row>
                                ) : (
                                    ""
                                )}
                                <Row className="question-y-n">
                                    <Col xs={{span: 11, offset: 1}}>
                    <span>
                      Je suis ou serai couvert par une mutuelle obligatoire ?
                    </span>
                                    </Col>
                                    <Col xs={12} className="quotation-radio">
                                        <MgcRadioToggleButtonGroup
                                            id="couvertMutuelle"
                                            error={errors.couvertMutuelle}
                                            touched={touched.couvertMutuelle}
                                            value={values.couvertMutuelle}
                                            dirty={true}
                                        >
                                            <Field
                                                component={MgcRadioToggleButton}
                                                name="couvertMutuelle"
                                                id="true"
                                                label={"Oui"}
                                            />
                                            <Field
                                                component={MgcRadioToggleButton}
                                                name="couvertMutuelle"
                                                id="false"
                                                label={"Non"}
                                            />
                                        </MgcRadioToggleButtonGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{span: 11, offset: 1}}>
                                        <h4>Autre(s) bénéficiaire(s)</h4>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={{span: 11, offset: 1}}>
                                        <Field
                                            className="check "
                                            name="conjointChecked"
                                            type="checkbox"
                                            component="input"
                                            id="conjointChecked"
                                        />
                                        <label for="conjointChecked">Mon Conjoint</label>
                                    </Col>
                                </Row>
                                {values.conjointChecked ? (
                                    <ComplementaryQuotationPerson
                                        tabRegimeAffiliation={this.state.regimeAssurance}
                                        errors={errors}
                                        dirty={dirty}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        conjoint={true}
                                        touched={touched}
                                    />
                                ) : (
                                    ""
                                )}
                                <Row>
                                    <Col xs={{span: 11, offset: 1}}>
                                        <Field
                                            className="check"
                                            name="childrenChecked"
                                            type="checkbox"
                                            component="input"
                                            id="children"
                                        />
                                        <label for="children">Mes enfants</label>
                                    </Col>
                                </Row>
                                {values.childrenChecked ? (
                                    <Row>
                                        <Col xs={{span: 10, offset: 1}}>
                                            <Field
                                                name="nbrEnfant"
                                                type="number"
                                                className="form-control"
                                                placeholder="Nombre d'enfants"
                                                setFieldValue={setFieldValue}
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    ""
                                )}
                                <Row>
                                    <Col xs={{span: 11, offset: 1}}>
                                        <h4 className="p-top-50">Mes coordonnées</h4>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={{span: 11, offset: 1}}>
                                        <QuotationAdresse
                                            errors={errors}
                                            dirty={dirty}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            withoutOffset={true}
                                            needLibelleOfTypeVoie={true}
                                            withoutCountry={true}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <MgcTextField
                                            dirty={dirty}
                                            errors={errors}
                                            name="telephone1"
                                            placeholder="N° de téléphone"
                                            className="form-control"
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <MgcTextField
                                            dirty={dirty}
                                            errors={errors}
                                            name="telephone2"
                                            placeholder="Autre N° de téléphone"
                                            className="form-control"
                                        />
                                    </Col>
                                    <Col
                                        xs={{span: 10, offset: 1}}
                                        className="quotation-information"
                                    >
                                        <p>
                                            En application de l’article L.121-34 du Code de la
                                            Consommation, vous avez la possibilité de vous inscrire
                                            gratuitement sur la liste d’opposition au démarchage
                                            téléphonique. Pour vous inscrire :{" "}
                                            <a href="http://www.bloctel.gouv.fr" target="_blank" rel="noopener noreferrer">www.bloctel.gouv.fr</a>
                                        </p>
                                    </Col>
                                    <Col xs={12}>
                                        <MgcTextField
                                            dirty={dirty}
                                            errors={errors}
                                            name="email"
                                            placeholder="Email"
                                            className="form-control"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ComplementaryQuotationRequirements
                                            errors={errors}
                                            dirty={dirty}
                                            setFieldValue={setFieldValue}
                                            touched={touched}
                                            values={values}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        className="ptop"
                                        xs={{span: 10, offset: 1}}
                                        lg={{span: 7, offset: 3}}
                                        md={{span:10, offset: 2}}
                                    >
                                        <button
                                            type="submit"
                                            className={` ${!isValid ? "btn-disabled" : "btn-submit"}`}
                                        >
                                            ENVOYER MA DEMANDE DE DEVIS
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        </form>
                    )}
                />
            </div>
        )
    }
}

export default ComplementatyQuotation

let initialValues = {
    nom: "",
    prenom: "",
    dateNaissance: "",
    adherentMGC: "",
    civilite: "",
    regimeAffiliation: "",
    couvertMutuelle: "",
    nbrEnfant: "",
    civiliteConjoint: "",
    prenomConjoint: "",
    nomConjoint: "",
    dateNaissanceConjoint: "",
    regimeAffiliationConjoint: "",
    optique: "",
    dentaire: "",
    hospitalisation: "",
    depasementHonoraires: "",
    autres: "",
}

function sendMail(value, changeStatus, numeroLatins) {
    const numeroLatin = numeroLatins.find(nl => {
        return nl.id+"" === value.adresseNumeroLatin
    })
/*    let URL = "http://localhost:8080/api/public/devis-surcomplementaire"*/
    const URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/devis-surcomplementaire`
    Axios.post(
        URL,
        {
            adherentMGC: value.adherentMGC && value.adherentMGC !== "false" ? "Oui": "Non",
            adherent:{
                civilite: value.civilite,
                nom: value.nom,
                prenom: value.prenom,
                dateNaissance: value.dateNaissance,
                regimeAssurance: value.regimeAffiliation,
            },
            email: value.email,
            telephone1: value.telephone1,
            telephone2: value.telephone2,
            couvertureMutuelle : value.couvertMutuelle ? "Oui": "Non",
            adresse: {
                appartement: value.appartement,
                batiment: value.batiment,
                numero: value.adresseNumero,
                numeroLatin:  numeroLatin ? numeroLatin.libelle : "",
                complementAlpha: value.adresseComplementAlpha ||'',
                typeVoie: 1,
                typeVoieLibelle: value.adresseTypeVoie,
                voie: value.adresseVoie,
                lieuDit: value.adresseLieuDit,
                codePostal: value.adresseCodePostal,
                ville: value.adresseVille,
                pays: value.adressePays,
            },
            conjoint:{
                civilite: value.civiliteConjoint,
                nom:  value.nomConjoint,
                prenom: value.prenomConjoint,
                dateNaissance: value.dateNaissanceConjoint,
                regimeAssurance: value.regimeAffiliationConjoint,
            },
            enfant: value.nbrEnfant,
            besoins: {
                depassement: value.depassementHonoraires,
                lunettesLentilles: value.optique,
                prothesesDentaires: value.dentaire,
                hospi: value.hospitalisation,
                auditif: value.autres
            }
        }
    ).then(() => {
        window.scrollTo(0, 0)

        var idc = value.nom + value.prenom;
        window.dataLayer.push({
            event: 'infos',
            user_data: {
                user_id: ATInternetTagService.hashTheID(idc),
                user_first_name: value.nom ,
                user_last_name: value.prenom,
                user_mail: value.email,
                user_phone: value.telephone1
            }
        });

        window.dataLayer.push({
            event: "quotation_form",
            form_name: "mutuelle_sante"
        });

        changeStatus("sended")

    }).catch(()=>{
            changeStatus("failed")
        window.scrollTo(0, 0)
    })
}
