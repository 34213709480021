import React from "react";
import FormulaLevelColumn from "./FormulaLevelsColumn"
import classNames from "classnames"

const CibleFormulePresentationTable = ({codeCouleur, formulas, nbFormule, tns, priceLink, niveauMax, niveauAidesAuditivesDisponible}) => {
  const labelClassNames = classNames("cibleFormulePresentationTable", {
      "width2Formule": nbFormule === 2,
      "width1Formule": nbFormule === 1
  })
  return (
    <div className={labelClassNames}>
      <div className="cibleFormulePresentationTableCol">
        <div className="title hide"></div>
        <div className="CibleFormulePresentationTableCol text">Soins courants</div>
        <div className="CibleFormulePresentationTableCol text">Hospitalisation</div>
        <div className="CibleFormulePresentationTableCol text">Optique</div>
        <div className="CibleFormulePresentationTableCol text">Dentaire</div>
        <div className="CibleFormulePresentationTableCol text">Médecines douces</div>
        { niveauAidesAuditivesDisponible && <div className="CibleFormulePresentationTableCol text">Aides auditives</div>}
        <div className="title hide"></div>
      </div>     
      { formulas.map((f) => (
        <FormulaLevelColumn key={f.libelle} niveauDentaire={f.niveauDentaire || 0} niveauOptique={f.niveauOptique || 0} lien={f.lien} libelle={f.libelle} niveauSoins={f.niveauSoins || 0} niveauMedecineDouce={f.niveauMedecineDouce || 0} niveauHospi={f.niveauHospi || 0} codeCouleur={codeCouleur} prixMensuel={f.prixMensuel} tns={tns} priceLink={priceLink} niveauAidesAuditives={f.niveauAidesAuditives || 0} niveauMax={f.niveauMax || 5} niveauAidesAuditivesDisponible={niveauAidesAuditivesDisponible}/>
      ))}
  </div>
);
}

export default CibleFormulePresentationTable;
