import React from "react"
import Slider from "react-slick"
import CibleFormuleNextArrowBouncy from "./cibleFormulesSliderNextArrowBouncy"
import CibleFormulePrevArrowBouncy from "./cibleFormulesSliderPrevArrowBouncy"
import FormulaLevelColumn from "./FormulaLevelsColumn"

const GammeFormulasMobile = ({ formulas, codeCouleur, tns, priceLink, niveauAidesAuditivesDisponible }) => {
  const settings = {
    nextArrow: <CibleFormuleNextArrowBouncy />,
    prevArrow: <CibleFormulePrevArrowBouncy />,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1000,
    niveauAidesAuditivesDisponible: niveauAidesAuditivesDisponible,
  }
  return (
    <div className="cibleSlider">
      <Slider {...settings}>
        {formulas.map(f => (
          <div key={f.libelle} className="cibleSlider">
            <FormulaLevelColumn
              niveauDentaire={f.niveauDentaire || 1}
              niveauOptique={f.niveauOptique || 1}
              lien={f.lien}
              libelle={f.libelle}
              niveauSoins={f.niveauSoins || 1}
              niveauHospi={f.niveauHospi || 1}
              niveauMedecineDouce={f.niveauMedecineDouce || 1}
              niveauAidesAuditives={f.niveauAidesAuditives || 1}
              codeCouleur={codeCouleur}
              prixMensuel={f.prixMensuel}
              tns={tns}
              niveauMax={f.niveauMax || 5}
              priceLink={priceLink}
              niveauAidesAuditivesDisponible={niveauAidesAuditivesDisponible}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default GammeFormulasMobile
