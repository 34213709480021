import React, { useEffect, useState } from "react"
import CookieModalSwitch from "../../components/global/cookieModalSwitch"
import { ATInternetTagService } from "../../services/global/ATInternetService.js"
import "../../components/global/cookieModal.css"

import {
  acceptedAudience,
  acceptedAds,
  acceptedSocialNetwork,
  acceptedOptout,
  setCookiesConsent,
} from "../../services/global/cookiesService"

import CookieModalTextInfo from "../../components/global/cookieModalTextInfo"

const OptoutAtInternetBlock = () => {
  const change = checked => {
    if (!checked) {
      ATInternetTagService.setOptoutMode()
    } else if (acceptedAudience()) {
      ATInternetTagService.setOptinMode()
    } else {
      ATInternetTagService.setCnilMode()
    }
    setCookiesConsent(
      /* !checked ? false : acceptedAudience(), A voir si on conserve ce truc */
      acceptedAudience(),
      acceptedAds(),
      acceptedSocialNetwork(),
      !checked
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col ">
          <div className="mgc-cookie-modal modal-x1">
            <CookieModalSwitch
              onChange={checked => change(checked)}
              checked={!acceptedOptout()}
              infoText={
                <CookieModalTextInfo
                  text={`Cookies tiers de mesure d'audience AT Internet bénéficiant de l'exemption de consentement*.`}
                />
              }
              afterTextOnMobile
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptoutAtInternetBlock
