import * as Yup from "yup"
import { checkTelephone } from "../validators/validatorUtils"

const devisTypeValidation = Yup.object().shape({
  adherent: Yup.string()
  .matches(/(true|false)/, { excludeEmptyString: false })
  .required("Champ obligatoire"),
  civilite: Yup.string()
  .matches(/(Monsieur|Madame)/, { excludeEmptyString: false })
  .required("Champ obligatoire"),
  nom: Yup.string().trim().required("Champ obligatoire"),
  prenom: Yup.string().trim().required("Champ obligatoire"),
  dateNaissance: Yup.string()
    .test(
      "test-majeur",
      "Il n'est pas possible d'adhérer avant 18 ans",
      function(value) {
        return checkAge(value)
      }
    )
    .required("Il n'est pas possible d'adhérer avant 18 ans"),
  numAdherent: Yup.string().when("adherent", {
    is: "true",
    then: Yup.string()
      .matches(/(^[0-9]{9}$)|(^[0-9]{5}$)|(^[0-9]{6}$)|(^[0-9]{7}$)|(^[0-9]{8}$)/, { excludeEmptyString: true, message:"Ce champ doit comporter une série de 5 à 9 chiffres" }),
    otherwise: Yup.string().notRequired(),
  }),
  civilite: Yup.string()
    .matches(/(Monsieur|Madame)/, { excludeEmptyString: false })
    .required("Champ obligatoire"),
  disponibilite: Yup.string()
    .matches(/(Matin|Midi|Après-midi)/, { excludeEmptyString: true })
    .notRequired(),
   otherwise: Yup.string().notRequired(),
   telephone1: Yup.string()
   .matches(/(^[0-9]{10}$)/, { excludeEmptyString: true, message:"Le numéro de téléphone doit comporter 10 chiffres" })
   .notRequired()
  })
export default devisTypeValidation

function checkAge(birthday) {
  let age = Date.parse(birthday)
  let ageDifMs = Date.now() - age
  let ageDate = new Date(ageDifMs)

  if (ageDate.getUTCFullYear() - 1970 >= 18) {
    return birthday
  } else {
    return false
  }
}

