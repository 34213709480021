 const GaugeCss = [
    { 0: 'zero' },
    { 1: 'one' },
    { 2: 'two' },
    { 3: 'three' },
    { 4: 'four' },
    { 5: 'five' },
    { 6: 'six' },
    { 7: 'seven' },
    { 8: 'eight' },
    { 9: 'nine' },
    { 10: 'ten' },
    { 11: 'eleven' },
    { 12: 'twelve' },
    { 13: 'thirteen' },
    { 14: 'fourteen' },
    { 15: 'fifteen' },
    { 16: 'sixteen' },
    { 17: 'seventeen' },
    { 18: 'eighteen' },
    { 19: 'nineteen' },
    { 20: 'twenty' },
    { 21: 'twenty-one' },
    { 22: 'twenty-two' },
    { 23: 'twenty-three' },
    { 24: 'twenty-four' },
    { 25: 'twenty-five' },
    { 26: 'twenty-six' },
    { 27: 'twenty-seven' },
    { 28: 'twenty-eight' },
    { 29: 'twenty-nine' },
    { 30: 'thirty' },
    { 31: 'thirty-one' },
    { 32: 'thirty-two' },
    { 33: 'thirty-three' },
    { 34: 'thirty-four' },
    { 35: 'thirty-five' },
    { 36: 'thirty-six' },
    { 37: 'thirty-seven' },
    { 38: 'thirty-eight' },
    { 39: 'thirty-nine' },
    { 40: 'forty' },
    { 41: 'forty-one' },
    { 42: 'forty-two' },
    { 43: 'forty-three' },
    { 44: 'forty-four' },
    { 45: 'forty-five' },
    { 46: 'forty-six' },
    { 47: 'forty-seven' },
    { 48: 'forty-eight' },
    { 49: 'forty-nine' },
    { 50: 'fifty' },
    { 51: 'fifty-one' },
    { 52: 'fifty-two' },
    { 53: 'fifty-three' },
    { 54: 'fifty-four' },
    { 55: 'fifty-five' },
    { 56: 'fifty-six' },
    { 57: 'fifty-seven' },
    { 58: 'fifty-eight' },
    { 59: 'fifty-nine' },
    { 60: 'sixty' },
    { 61: 'sixty-one' },
    { 62: 'sixty-two' },
    { 63: 'sixty-three' },
    { 64: 'sixty-four' },
    { 65: 'sixty-five' },
    { 66: 'sixty-six' },
    { 67: 'sixty-seven' },
    { 68: 'sixty-eight' },
    { 69: 'sixty-nine' },
    { 70: 'seventy' },
    { 71: 'seventy-one' },
    { 72: 'seventy-two' },
    { 73: 'seventy-three' },
    { 74: 'seventy-four' },
    { 75: 'seventy-five' },
    { 76: 'seventy-six' },
    { 77: 'seventy-seven' },
    { 78: 'seventy-eight' },
    { 79: 'seventy-nine' },
    { 80: 'eighty' },
    { 81: 'eighty-one' },
    { 82: 'eighty-two' },
    { 83: 'eighty-three' },
    { 84: 'eighty-four' },
    { 85: 'eighty-five' },
    { 86: 'eighty-six' },
    { 87: 'eighty-seven' },
    { 88: 'eighty-eight' },
    { 89: 'eighty-nine' },
    { 90: 'ninety' },
    { 91: 'ninety-one' },
    { 92: 'ninety-two' },
    { 93: 'ninety-three' },
    { 94: 'ninety-four' },
    { 95: 'ninety-five' },
    { 96: 'ninety-six' },
    { 97: 'ninety-seven' },
    { 98: 'ninety-eight' },
    { 99: 'ninety-nine' },
    { 100: 'hundred' },
    { 101: 'hundred-one' },
    { 102: 'hundred-two' },
    { 103: 'hundred-three' },
  ];

    const GaugeCssOffset = [
        { 0: 'offset-zero' },
        { 1: 'offset-one' },
        { 2: 'offset-two' },
        { 3: 'offset-three' },
        { 4: 'offset-four' },
        { 5: 'offset-five' },
        { 6: 'offset-six' },
        { 7: 'offset-seven' },
        { 8: 'offset-eight' },
        { 9: 'offset-nine' },
        { 10: 'offset-ten' },
        { 11: 'offset-eleven' },
        { 12: 'offset-twelve' },
        { 13: 'offset-thirteen' },
        { 14: 'offset-fourteen' },
        { 15: 'offset-fifteen' },
        { 16: 'offset-sixteen' },
        { 17: 'offset-seventeen' },
        { 18: 'offset-eighteen' },
        { 19: 'offset-nineteen' },
        { 20: 'offset-twenty' },
        { 21: 'offset-twenty-one' },
        { 22: 'offset-twenty-two' },
        { 23: 'offset-twenty-three' },
        { 24: 'offset-twenty-four' },
        { 25: 'offset-twenty-five' },
        { 26: 'offset-twenty-six' },
        { 27: 'offset-twenty-seven' },
        { 28: 'offset-twenty-eight' },
        { 29: 'offset-twenty-nine' },
        { 30: 'offset-thirty' },
        { 31: 'offset-thirty-one' },
        { 32: 'offset-thirty-two' },
        { 33: 'offset-thirty-three' },
        { 34: 'offset-thirty-four' },
        { 35: 'offset-thirty-five' },
        { 36: 'offset-thirty-six' },
        { 37: 'offset-thirty-seven' },
        { 38: 'offset-thirty-eight' },
        { 39: 'offset-thirty-nine' },
        { 40: 'offset-forty' },
        { 41: 'offset-forty-one' },
        { 42: 'offset-forty-two' },
        { 43: 'offset-forty-three' },
        { 44: 'offset-forty-four' },
        { 45: 'offset-forty-five' },
        { 46: 'offset-forty-six' },
        { 47: 'offset-forty-seven' },
        { 48: 'offset-forty-eight' },
        { 49: 'offset-forty-nine' },
        { 50: 'offset-fifty' },
        { 51: 'offset-fifty-one' },
        { 52: 'offset-fifty-two' },
        { 53: 'offset-fifty-three' },
        { 54: 'offset-fifty-four' },
        { 55: 'offset-fifty-five' },
        { 56: 'offset-fifty-six' },
        { 57: 'offset-fifty-seven' },
        { 58: 'offset-fifty-eight' },
        { 59: 'offset-fifty-nine' },
        { 60: 'offset-sixty' },
        { 61: 'offset-sixty-one' },
        { 62: 'offset-sixty-two' },
        { 63: 'offset-sixty-three' },
        { 64: 'offset-sixty-four' },
        { 65: 'offset-sixty-five' },
        { 66: 'offset-sixty-six' },
        { 67: 'offset-sixty-seven' },
        { 68: 'offset-sixty-eight' },
        { 69: 'offset-sixty-nine' },
        { 70: 'offset-seventy' },
        { 71: 'offset-seventy-one' },
        { 72: 'offset-seventy-two' },
        { 73: 'offset-seventy-three' },
        { 74: 'offset-seventy-four' },
        { 75: 'offset-seventy-five' },
        { 76: 'offset-seventy-six' },
        { 77: 'offset-seventy-seven' },
        { 78: 'offset-seventy-eight' },
        { 79: 'offset-seventy-nine' },
        { 80: 'offset-eighty' },
        { 81: 'offset-eighty-one' },
        { 82: 'offset-eighty-two' },
        { 83: 'offset-eighty-three' },
        { 84: 'offset-eighty-four' },
        { 85: 'offset-eighty-five' },
        { 86: 'offset-eighty-six' },
        { 87: 'offset-eighty-seven' },
        { 88: 'offset-eighty-eight' },
        { 89: 'offset-eighty-eight' },
        { 90: 'offset-ninety' },
        { 91: 'offset-ninety-one' },
        { 92: 'offset-ninety-two' },
        { 93: 'offset-ninety-three' },
        { 94: 'offset-ninety-four' },
        { 95: 'offset-ninety-five' },
        { 96: 'offset-ninety-six' },
        { 97: 'offset-ninety-seven' },
        { 98: 'offset-ninety-eight' },
        { 99: 'offset-ninety-nine' },
        { 100: 'offset-hundred' },
        { 101: 'offset-hundred-one' },
        { 102: 'offset-hundred-two' },
        { 103: 'offset-hundred-three' }];
    

    function isNumberCandidate(s) {
        const str = (''+ s).trim();
        if (str.length === 0) return false;
        return true;
      }
    
    export default function getCssGauge(value, depense) {
        if(isNumberCandidate(value) && isNumberCandidate(depense)) {
            const valueInNumber = parseFloat(value);
            const depenseInNumber = parseFloat(depense);
            if (depenseInNumber === 0){
                return 0;
            } 
            const valueInGauge = Math.round(((101 * valueInNumber) / depenseInNumber));
                return  valueInGauge;
        }
        return 0;
    }

    export function getFillClasses(value, depense) {
        const remboursementPart = getCssGauge(value, depense);
        const classRemb = GaugeCss[remboursementPart];
        const classResteACharge = GaugeCssOffset[remboursementPart];
        return {'rembClass': classRemb[remboursementPart], 'resteAChargeClass': classResteACharge[remboursementPart] };
    }