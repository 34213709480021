import * as Yup from "yup"
const deathBenefitValidation = Yup.object().shape({
  nom: Yup.string().trim().required("nom is required"),
  prenom: Yup.string().trim().required("prenom is required"),
  dateNaissance: Yup.string()
    .test(
      "test-majeur",
      "Il n'est pas possible d'adhérer avant 18 ans",
      function(value) {
        return checkAge(value)
      }
    )
    .required("Il n'est pas possible d'adhérer avant 18 ans"),
  situationFamiliale: Yup.string()
    .test("test-situationFamiliale", "situationFamiliale is required", function(
      value
    ) {
      return checkSelect(value)
    })
    .required("situationFamilliale is required"),
  adherent: Yup.string()
    .matches(/(true|false)/, { excludeEmptyString: false })
    .required("adherent is required"),
  numAdherent: Yup.string().when("adherent", {
    is: "true",
    then: Yup.string()
      .matches(/^[0-9]{10}$/, { excludeEmptyString: true })
      .required("budget is required"),
    otherwise: Yup.string().notRequired(),
  }),
  civilite: Yup.string()
    .matches(/(Monsieur|Madame)/, { excludeEmptyString: false })
    .required("civilite is required"),
  adresseLieuDit: Yup.string(),
  appartement: Yup.string(),
  batiment: Yup.string(),
  adresseNumero: Yup.string(),
  adresseNumeroLatin: Yup.string(),
  email: Yup.string()
    .email()
    .required("email is required"),
  adresseTypeVoie: Yup.string().required("adresseTypeVoie is required"),
  adresseVoie: Yup.string().required("adresseVoie is required"),
  adresseCodePostal: Yup.string().required("adresseCodePostal is required"),
  adressePays: Yup.string().required("adressePays is required"),
  adresseVille: Yup.string().required("adresseVille is required"),
  adresseComplementAlpha: Yup.string().matches(/^[A-Z]{1}$/, {
    excludeEmptyString: true,
  }),
  telephone1: Yup.string()
    .matches(/^(\\+33|0|0033)[0-9]{9}$/, { excludeEmptyString: true })
    .required("telephone1 is required"),
  telephone2: Yup.string().matches(/^(\\+33|0|0033)[0-9]{9}$/, {
    excludeEmptyString: true,
  }),
  critereCalculEtude: Yup.string()
    .matches(/(capital|budget)/, { excludeEmptyString: false })
    .required("critereCalculEtude is required"),
  capitalSouhaite: Yup.string().when("critereCalculEtude", {
    is: "capital",
    then: Yup.string()
      .test("test-capital", "capital is required", function(value) {
        return checkSelect(value)
      })
      .required("capital is required"),
    otherwise: Yup.string().notRequired(),
  }),
  budget: Yup.string().when("critereCalculEtude", {
    is: "budget",
    then: Yup.string().matches(/^[0-9]{1,9}$/, { excludeEmptyString: true, message:"Le budget doit être un montant" })
    .test("test-budget", "Le montant doit être compris entre 5€ et 200€", function(value) {
      return checkBudget(value)
    }).required(" "),
    otherwise: Yup.string().notRequired(),
  }),
  modeObseque: Yup.string()
    .test("test-modeObseque", "modeObseque is required", function(value) {
      return checkSelect(value)
    })
    .required("modeObseque is required"),
  //conjoint
  offreCoupDouble: Yup.string()
    .matches(/(true|false)/, { excludeEmptyString: false })
    .required("offreCoupDouble is required"),
  civiliteConjoint: Yup.string().when("offreCoupDouble", {
    is: "true",
    then: Yup.string()
      .matches(/(homme|femme)/, { excludeEmptyString: false })
      .required("civilite is required"),
    otherwise: Yup.string().notRequired(),
  }),
  nomConjoint: Yup.string().when("offreCoupDouble", {
    is: "true",
    then: Yup.string().trim().required("nomConjoint is required"),
    otherwise: Yup.string().notRequired(),
  }),
  prenomConjoint: Yup.string().when("offreCoupDouble", {
    is: "true",
    then: Yup.string().trim().required("prenomConjoint is required"),
    otherwise: Yup.string().notRequired(),
  }),
  dateNaissanceConjoint: Yup.string().when("offreCoupDouble", {
    is: "true",
    then: Yup.string()
      .test(
        "test-majeur",
        "Il n'est pas possible d'adhérer avant 18 ans",
        function(value) {
          return checkAge(value)
        }
      )
      .required("Il n'est pas possible d'adhérer avant 18 ans"),
    otherwise: Yup.string().notRequired(),
  }),
  critereCalculEtudeConjoint: Yup.string().when("offreCoupDouble", {
    is: "true",
    then: Yup.string()
      .matches(/(capital|budget)/, { excludeEmptyString: false })
      .required("critereCalculEtude is required"),
    otherwise: Yup.string().notRequired(),
  }),
  modeObsequeConjoint: Yup.string().when("offreCoupDouble", {
    is: "true",
    then: Yup.string()
      .test(
        "test-modeObseque-conjoint",
        "modeObseque-conjoint is required",
        function(value) {
          return checkSelect(value)
        }
      )
      .required("modeObseque-conjoint is required"),
    otherwise: Yup.string().notRequired(),
  }),
  budgetConjoint: Yup.string().when('critereCalculEtudeConjoint', {
    is: "budgetConjoint",
    then: Yup.string().matches(/^[0-9]{1,9}$/, { excludeEmptyString: true, message:"Le budget doit être un montant" })
      .test("test-budget", "Le montant doit être compris entre 5€ et 200€", function(value) {
       return checkBudget(value)
      }).required(" "),
    otherwise: Yup.string().notRequired(),
  }),
  capitalSouhaiteConjoint: Yup.string().when("offreCoupDouble", {
    is: "true",
    then: Yup.string().when("critereCalculEtudeConjoint", {
      is: "capital",
      then: Yup.string()
        .test(
          "test-capitalSouhaiteConjoint",
          "capitalSouhaiteConjoint is required",
          function(value) {
            return checkSelect(value)
          }
        )
        .required("capitalSouhaiteConjoint is required"),
      otherwise: Yup.string().notRequired(),
    }),
    otherwise: Yup.string().notRequired(),
  }),

  budgetConjoint: Yup.string().when("offreCoupDouble", {
    is: "true",
    then: Yup.string().when("critereCalculEtudeConjoint", {
      is: "budget",
      then: Yup.string()
        .matches(/^[0-9]{1,4}$/, { excludeEmptyString: true })
        .required("budgetConjoint is required"),
      otherwise: Yup.string().notRequired(),
    }),
    otherwise: Yup.string().notRequired(),
  }),
})

export default deathBenefitValidation

function checkSelect(val) {
  if (val !== "null" && val) return val
  return false
}

function checkBudget(val) {
  if (val !== "null" && val){
    if(val < 5 || val > 200){
      return false
    }
    return val
  }
  return true
}

function checkAge(birthday) {
  let age = Date.parse(birthday)
  let ageDifMs = Date.now() - age
  let ageDate = new Date(ageDifMs)

  if (ageDate.getUTCFullYear() - 1970 >= 18) {
    return birthday
  } else {
    return false
  }
}
