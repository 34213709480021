import React from "react"
import CibleFormulePresentation from "../../components/cibleFormulePresentation"
import CibleFormulesGammeButton from "../../components/cibleFormuleGammeButton"
import GammeFormulasMobile from "../../components/gammeFormulasMobile"
import CibleFormulePresentationOffer from "../../components/cibleFormulePresentationOffer"

const GammeFormulasBlock = ({ data, media }) => {
    const getPriceLinkFormule = (formule, cheminot) => {
        const {prixMensuel, prixMensuelCheminot, lien, lienCheminot} = formule
        const result = {
            ...formule,
            prixMensuel: cheminot ? prixMensuelCheminot : prixMensuel,
            lien: cheminot ? lienCheminot : lien,
        }
        return result
    }

    const {
        title,
        subTitle,
        cheminot,
        gamme,
        offerConditionsText,
        offerConditionsLink,
        buttonText,
        mediaPdf,
        offerImage,
        offerURL,
        priceLink,
        alt
    } = data
    const { formules, code, niveauAidesAuditivesDisponible } = gamme || {}

    const sortedFormulas = formules.sort((a, b) => a.ordre - b.ordre).map(formule => {
        return getPriceLinkFormule(formule, cheminot)
    })

    const codeCouleur =
        sortedFormulas && sortedFormulas.length > 0
            ? sortedFormulas[0].codeCouleur
            : ""
    const levels = Math.ceil(formules.length / 3)
    const nbFormLastLevel = formules.length % 3
    const tns = data.gamme.code === "TNS"
    return (
          <div className={'gammeFormulaBlock'}>
                <section className="cibleFormule">
                    <div className="cibleFormuleHeader">
                        <h3 className="cibleFormuleHeaderTitle">{title}</h3>
                        <p className="cibleFormuleHeaderSubtitle">{subTitle}</p>
                    </div>
                </section>
                {formules.length > 1 && (
                    <section className="cibleFormule">
                        <div className="container">
                            <CibleFormulePresentation
                                withOffer={true}
                                alt={alt}
                                codeCouleur={codeCouleur}
                                codeGamme={code}
                                formulas={sortedFormulas.slice(0, 3)}
                                offerConditionsText={offerConditionsText}
                                offerConditionsLink={offerConditionsLink}
                                offerURL={offerURL}
                                offerImage={offerImage && offerImage.image ? media[offerImage.image.id] : ""}
                                nbFormule={levels < 2 ? nbFormLastLevel:3}
                                tns={tns}
                                priceLink={priceLink}
                                niveauAidesAuditivesDisponible={niveauAidesAuditivesDisponible}
                            />
                        </div>
                    </section>
                )}
                {levels > 1 && (
                    <section className="cibleFormule">
                        <div className="container">
                            <CibleFormulePresentation
                                withOffer={false}
                                codeCouleur={codeCouleur}
                                formulas={sortedFormulas.slice(3, 6)}
                                nbFormule={levels < 3 ? nbFormLastLevel:3}
                                tns={tns}
                                priceLink={priceLink}
                                niveauAidesAuditivesDisponible={niveauAidesAuditivesDisponible}
                            />
                        </div>
                    </section>
                )}
                {levels > 2 && (
                    <section className="cibleFormule">
                        <div className="container">
                            <CibleFormulePresentation
                                withOffer={false}
                                codeCouleur={codeCouleur}
                                formulas={sortedFormulas.slice(6, 10)}
                                subClass="callback"
                                nbFormule={nbFormLastLevel}
                                tns={tns}
                                priceLink={priceLink}
                                niveauAidesAuditivesDisponible={niveauAidesAuditivesDisponible} 
                            />
                        </div>
                    </section>
                )}
                <GammeFormulasMobile
                    formulas={sortedFormulas}
                    codeCouleur={codeCouleur}
                    tns={tns}
                    priceLink={priceLink}
                    niveauAidesAuditivesDisponible={niveauAidesAuditivesDisponible}
                />
                {mediaPdf !== null && mediaPdf.image !== null ? (
                    <CibleFormulesGammeButton
                        linkButton={media[mediaPdf.image.id]}
                        textButton={buttonText}
                    />
                ) : (
                    <></>
                )}
                <CibleFormulePresentationOffer
                    codeGamme={code}
                    offerConditionsLink={offerConditionsLink}
                    offerConditionsText={offerConditionsText}
                    rootClass="callback"
                />
            </div> 
    )
}

export default GammeFormulasBlock
