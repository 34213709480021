import React from "react"
import { Row, Col } from "react-bootstrap"
import MgcRadioToggleButton from "../mgcRadioToggleButton"
import MgcDateField from "../mgcDateField"
import MgcSelectField from "../mgcSelectField"
import MgcTextField from "../mgcTextField"
import { Field } from "formik"
import MgcRadioToggleButtonGroup from "../mgcRadioToggleButtonGroup"
import MgcNewDateField from "../mgcNewDateField";
class ComplementaryQuotationPerson extends React.Component {
  render() {
    const {
      tabRegimeAffiliation,
      errors,
      dirty,
      values,
      setFieldValue,
      conjoint,
      touched,
    } = this.props
    return (
      <>
        <Row className="question-y-n">
          <Col xs={12} className="quotation-radio">
            <MgcRadioToggleButtonGroup
              id={conjoint ? "civiliteConjoint" : "civilite"}
              error={errors.civilite}
              touched={touched.civilite}
              value={values.civilite}
              dirty={true}
            >
              <Field
                component={MgcRadioToggleButton}
                name={conjoint ? "civiliteConjoint" : "civilite"}
                id="Monsieur"
                label={"Monsieur"}
                error={errors.civilite}
              />
              <Field
                component={MgcRadioToggleButton}
                name={conjoint ? "civiliteConjoint" : "civilite"}
                id="Madame"
                label={"Madame"}
              />
            </MgcRadioToggleButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MgcTextField
              dirty={dirty}
              errors={errors}
              name={conjoint ? "nomConjoint" : "nom"}
              placeholder="Nom"
              className="form-control"
            />
          </Col>
          <Col xs={12}>
            <MgcTextField
              dirty={dirty}
              errors={errors}
              name={conjoint ? "prenomConjoint" : "prenom"}
              placeholder="Prénom"
              className="form-control"
            />
          </Col>
        </Row>
        <Row>
          {!conjoint &&
          errors.dateNaissance &&
          (values.dateNaissance &&
          values.dateNaissance.length > 6) ? (
            <Col xs={11} className="alert-majeur">
              {" "}
              <span className="">
                Il n'est pas possible d'adhérer avant 18 ans
              </span>
            </Col>
          ) : conjoint &&
            errors.dateNaissanceConjoint &&
            values.dateNaissanceConjoint.length > 6 ? (
            <Col xs={11} className="alert-majeur form-control">
              {" "}
              <span className="">
                Il n'est pas possible d'adhérer avant 18 ans
              </span>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col xs={{ span: 11, offset: 1 }}>
            <span>Date de naissance:</span>
          </Col>
          <Col >
{/*            <MgcDateField
              dirty={dirty}
              errors={errors}
              name={conjoint ? "dateNaissanceConjoint" : "dateNaissance"}
              className="form-control"
              setFieldValue={setFieldValue}
            />*/}
              <MgcNewDateField
                  dirty={dirty}
                  errors={errors}
                  name={conjoint ? "dateNaissanceConjoint" : "dateNaissance"}
                  className="form-control"
                  setFieldValue={setFieldValue}
                  values={values}
              />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 11, offset: 1 }} className="">
            <MgcSelectField
              dirty={dirty}
              name={
                conjoint ? "regimeAffiliationConjoint" : "regimeAffiliation"
              }
              errors={errors}
              firstOption="- Régime d'affiliation -"
              className="form-control "
              setFieldValue={setFieldValue}
              table={tabRegimeAffiliation ? tabRegimeAffiliation.data : []}
            />
          </Col>
        </Row>
      </>
    )
  }
}
export default ComplementaryQuotationPerson
