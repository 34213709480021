import React from "react"
import FormulaHeaderDescriptionTable from "../../components/FormulaHeaderDescriptionTable"
import IconUmbrella from "../../images/icon-umbrella.png"
import ButtonFormulaHeaderBlock from "../../components/buttonFormulaHeaderBlock"
import { ATInternetTagService } from './../../services/global/ATInternetService';

const FormulaHeaderBlock = ({ data }) => {
  const {
    title,
    titleRight,
    textRight,
    buttonRight,
    knowMore,
    textLeftLink,
    leftLink,
    cheminot,
    aelLinkBtn,
    quotLinkBtn,
  } = data
  const { formule, gamme } = data
  const {
    niveauOptique,
    niveauDentaire,
    niveauHospi,
    niveauSoins,
    niveauMedecineDouce,
    lien,
    lienCheminot,
    codeCouleur,
    prixMensuel,
    prixMensuelCheminot,
    niveauMax,
    niveauAidesAuditives
  } = formule || {}
  const { 
    niveauAidesAuditivesDisponible } = gamme || {}

  const getMonthPrice = (prixMensuel, prixMensuelCheminot, cheminot) => {
    const monthPrice = cheminot ? prixMensuelCheminot : prixMensuel
    const formattedMonchPrice = monthPrice
      ? Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(monthPrice)
      : "-"
    return formattedMonchPrice
  }

  const generatedLien = cheminot && lienCheminot ? lienCheminot : lien
  const monthPrice = getMonthPrice(prixMensuel, prixMensuelCheminot, cheminot)

  // pour le moent on le garde en comm, mais à virer à terme (après validation AT_INTERNET ?)
  //ATInternetTagService.sendPage({page:data.formule ? data.formule.libelle:'',page_chapter1:ATInternetTagService.getPageChapter()})

  return (
    <div>
      <section className="formuleHeader">
        <div
          className={`formuleHeaderDescription ${codeCouleur}`}
          style={
            data.secondProduct === false || !data.secondProduct
              ? { width: "100%" }
              : {}
          }
        >
          <div className="formuleHeaderDescriptionContainer">
            <h3 className="formuleHeaderDescriptionTitle">{title}</h3>
            <div className={"dot-price"}>
              <span className={"dot " + codeCouleur}>
                à partir de {monthPrice}/mois<sup>(1)</sup>
              </span>
            </div>
            <FormulaHeaderDescriptionTable
              niveauOptique={niveauOptique}
              niveauDentaire={niveauDentaire}
              niveauHospi={niveauHospi}
              niveauSoins={niveauSoins}
              niveauMedecineDouce={niveauMedecineDouce}
              niveauAidesAuditives={niveauAidesAuditives}
              lien={generatedLien}
              codeCouleur={codeCouleur} 
              niveauMax={niveauMax || 5} 
              niveauAidesAuditivesDisponible={niveauAidesAuditivesDisponible}/>
            <div className={"dot-price-mobile"}>
              <span className={"dot " + codeCouleur}>
                à partir de {monthPrice}/mois<sup>(1)</sup>
              </span>
            </div>
            <div className={"formuleHeader-link"}>
              <a href={leftLink}> {textLeftLink} </a>
            </div>
          </div>
        </div>
        {data.secondProduct && (
          <div className={`formuleHeaderLink  ${codeCouleur}`}>
            <div className="formuleHeaderLinkContainer">
              <img
                src={IconUmbrella}
                className="formuleHeaderLinkImage"
                alt={titleRight}
              />
              <h3 className="formuleHeaderLinkTitle" dangerouslySetInnerHTML={{__html: titleRight}}/>
              <p className="formuleHeaderLinkText">{textRight}</p>
              <form action={knowMore}>
                <button type="submit" className="formuleHeaderLinkButton" onClick={() => ATInternetTagService.sendNavClick({click:buttonRight})}>
                  {buttonRight}
                </button>
              </form>
            </div>
          </div>
        )}
      </section>
      {(aelLinkBtn || quotLinkBtn) && (
        <ButtonFormulaHeaderBlock
          aelLinkBtn={aelLinkBtn}
          quotLinkBtn={quotLinkBtn}
        />
      )}
      {data.secondProduct && (
          <a href={knowMore} >
        <div
          className={
            aelLinkBtn || quotLinkBtn
              ? "secondOfferMobile-contain"
              : "secondOfferMobile-contain no-button"
          }
        >

          <div className={"secondOfferMobile " + codeCouleur}>
            <img
              src={IconUmbrella}
              className="formuleHeaderLinkImage"
              alt={titleRight}
            />
            <h3 className="formuleHeaderLinkTitle" dangerouslySetInnerHTML={{__html: titleRight}}/>
          </div>
        </div>
          </a>
      )}
    </div>
  )
}

export default FormulaHeaderBlock
