import React, { useEffect, useRef, useState } from "react"
import { Formik, Field, Form } from "formik";
import Slider from "react-slick";
import PictoMedecinesDouces from "../../components/PictoMedecinesDouces";
import CibleFormuleNextArrow from "../../components/cibleFormulesSliderNextArrow";
import CibleFormulePrevArrow from "../../components/cibleFormulesSliderPrevArrow";
import { getFillClasses } from "../../services/simulateur/gauge-css";
import { isMobile } from "react-device-detect"

const RacSimulatorView = (props) => {

    const [soin, setSoin] = useState(null);
    const [depense, setDepense] = useState(props.simulatorValues.depense);
    const [simulatorValues, setSimulatorValues] = useState(props.simulatorValues);
    const [initialValues, setInitialValues] = useState({ depense: props.simulatorValues.depense });
    const [isDepenseFromInput, setIsDepenseFromInput] = useState(false);

    const prestationId = 'prestationId';
    const sousNiveauSoinId = 'sousNiveauSoinId';
    const typeSoin = "typeSoin";
    const depenseKey = 'depense';

    const ref = useRef();
    const inputRef = useRef(null);

    const settings = {
        arrows: true,
        nextArrow: <CibleFormuleNextArrow />,
        prevArrow: <CibleFormulePrevArrow />,
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnHover: true,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: isMobile ? 1 : 3,
        className: 'formula-slider-simulator'
    };

    useEffect(() => {
        if (props.firstPrestation) {
            const defaultSoin = defaultTypeSoin(props.firstPrestation, props.typeSoins);
            setSoin(defaultSoin);
        }

        if (props.simulatorValues) {
            setSimulatorValues(props.simulatorValues);
            if (props.simulatorValues.depense != null) {
                if (isDepenseFromInput === false) {
                    setInitialValues({ depense: props.simulatorValues.depense + '€' });
                }
            }
        }

    }, [props.firstPrestation, props.simulatorValues, props.simulatorValues.depense]);


    function defaultTypeSoin(firstPrestation, typeSoins) {
        if (firstPrestation != null) {
            return typeSoins.filter(s => s.value === firstPrestation.typeSoin)[0];
        }
        return null;
    }

    function onSelectSoin(s) {
        setSoin(s);
        setIsDepenseFromInput(false);
        if (depense === 0) {
            props.onChange([{ key: typeSoin, value: s.value }, { key: prestationId, value: null }, { key: sousNiveauSoinId, value: null }, { key: depenseKey, value: null }]);
        } else {
            props.onChange([{ key: typeSoin, value: s.value }, { key: prestationId, value: null }, { key: sousNiveauSoinId, value: null }, { key: depenseKey, value: null }]);
        }
    }

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 10000);
        };
    };

    const handleDepenseChange = (value) => {
        props.onChange([{ key: depenseKey, value: value }]);
    }

    const onDepenseValueChange = (value) => {
        if (value.includes('€')) {
            value = value.replace('€', '');
        }
        
        if (value.length < 9) {
            if ((value === '' || isValidDouble(value) === true) && isCorrectValue(value) === true) {
                setIsDepenseFromInput(true);
                if (value !== '') {
                    setInitialValues({ depense: value + '€' });
                    const valuefixed = value.replace(",", ".").trim();
                    debounce(handleDepenseChange(valuefixed));
                } else {
                    setInitialValues({ depense: '' + '€' });
                }       
             }
        }
    }

   function handleKeyDown(event) {

    let depense = initialValues['depense'];

    if (depense != null && depense.includes('€') === true ) {
        if (event.keyCode === 8 || event.keyCode === 46) { 
            depense = depense.replace('€', '');
            setInitialValues({ depense: depense});
       } else if (event.keyCode === 110 || event.keyCode === 190) {
            setTimeout(() => {
                setCaretPosition(inputRef.current, (depense.length));
            });
      } else if((event.keyCode >= 48 && event.keyCode <=57) || (event.keyCode >= 96 && event.keyCode <=105)) { 
           setTimeout(() => {
              setCaretPosition(inputRef.current, (depense.length));
           });
       }
    }
  }

    function onFormValueChange(key, value) {
        setIsDepenseFromInput(false);
        if (key === prestationId) {
            props.onChange([{ key: prestationId, value: value }, { key: sousNiveauSoinId, value: null }]);
        } else {
            props.onChange([{ key: key, value: value }]);
        }
    }

    function onEditDepense() {
        const dep = document.querySelector('#depense');
        if (dep != undefined || dep != null) {
            dep.focus();
        }
    }

    function isValidDouble(val) {
        if (/^(?!,$)[\d,.]+$/.test(val) === true) {
            return true;
        }
        return false;
    }

    function isCorrectValue(value) {
        if ((value.includes('.') === true) || (value.includes(',') === true)) {
            let dotPosition = value.indexOf(".");
            dotPosition = (dotPosition !== -1) ? value.indexOf(".") : value.indexOf(",");
            if (dotPosition !== -1 && ((value.substring(0, dotPosition).length > 5) || value.substring(dotPosition + 1, value.length).length > 2)) {
                return false;
            } else {
                return true;
            }
        } else if (value.length > 5) {
            return false;
        }
        return true;
 }

    function onscroll() {
        if(ref.current !== null || ref.current !== undefined){
            ref.current.scrollIntoView({ behavior: 'smooth' });
        } 
    }

    function setCaretPosition (ctrl, pos) {
        // Modern browsers
        console.log('ctrl');
        if (ctrl !== null && ctrl.setSelectionRange) {
           ctrl.focus();
           ctrl.setSelectionRange(pos, pos);
         }
      }
     
     // afficher en fonction d'un ordre bien spécifique les formule pour la vue desktop, fficher la formule au milieu
    function moveItemDown(id, formules, isMobile) {
        let index = formules.findIndex(e => e.formuleId === id);
        if (isMobile === false){
            if (index !== -1 && formules.length >= 2) {
                let el = formules[index];
                formules[index] = formules[1];
                formules[1] = el;
            }
          } else {
                if (index !== -1 && formules.length >= 1) {
                    let el = formules[index];
                    formules[index] = formules[0];
                    formules[0] = el;
                }
          }
       
        return formules;
    }

    return (isMobile === false ?
        <div className='container'>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}>

                <Form className="raz-simulator-block">
                    {props.showTitle && (
                        <div className="row">
                            <h3 className="mobile-title">
                                SIMULATEUR DE REMBOURSEMENT
                            </h3>
                        </div>
                    )}
                    {
                        simulatorValues.situationProfessionnelleDTO && <div className="row row-section">
                            <div className="section1">
                                <div className="row">
                                    <label className="col-2 situation-prof-label">Je suis</label>
                                    <div className="col-10 form-group">
                                        <Field
                                            name="situationProfessionnelleId"
                                            component="select"
                                            className="select-input"
                                            onChange={(e) => {
                                                onFormValueChange(e.target.name, e.target.value);
                                            }}>
                                            {simulatorValues.situationProfessionnelleDTO.map(e => {
                                                return (
                                                    <option value={e.id} key={e.id} className="select-style">
                                                        {e.libelle}
                                                    </option>
                                                )
                                            })}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="section2"></div>
                        </div>
                    }
                    
                   <div className="row row-section-soin">
                     <div className="section-soin">
                            <div className="row">
                                {
                                    props.typeSoins.map(s => {
                                        return (
                                            <div key={s.value} className="col-2 type-soin-item" onClick={e => { onSelectSoin(s) }}>
                                                <div className="type-soin-checked" hidden={!(soin && soin.value === s.value)}><i className="fas fa-check"></i></div>
                                                <div className={'type-soin ' + ((soin && soin.value === s.value) ? 'type-soin-selected' : 'type-soin-noselected')}>
                                                    <div className="d-flex justify-content-center">
                                                        {s.isSvg ? <i style={{ color: '#ffffff' }}> <PictoMedecinesDouces width='45px' height='45px' /> </i> : <i className={s.icon} />}
                                                    </div>
                                                    <label>{s.text}</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                   </div>

                    <div className="row row-section mt-3">
                        <div className="section1">
                            <div className="row">
                                <label className="col-2 situation-prof-label">Prestation</label>
                                <div className="col-10 form-group">
                                    <Field
                                        name="prestationId"
                                        component="select"
                                        className="select-input"
                                        onChange={(e) => {
                                            onFormValueChange(e.target.name, e.target.value);
                                        }}>
                                        {simulatorValues.prestationDTO && simulatorValues.prestationDTO.map(e => {
                                            return (
                                                <option value={e.id} key={e.id} className="select-style">
                                                    {e.libelle}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="section2">
                            <div className="infos-help" onClick={(e) => {
                                onscroll();
                            }}><i className="fas fa-info"></i></div>
                        </div>
                    </div>

                    <div className="row row-section">
                        <div className="section1">
                            {
                                simulatorValues.sousNiveauSoinsDTO && simulatorValues.sousNiveauSoinsDTO[0].libelle != 'x' && (
                                    <div className="row">
                                        <label className="col-3 situation-prof-label">Sous-niveau de soins</label>
                                        <div className="col-9 form-group">
                                            <Field
                                                name="sousNiveauSoinId"
                                                component="select"
                                                className="select-input"
                                                onChange={(e) => {
                                                    onFormValueChange(e.target.name, e.target.value);
                                                }}>
                                                {simulatorValues.sousNiveauSoinsDTO.map(e => {
                                                    return (
                                                        <option value={e.id} key={e.id} className="select-style">
                                                            {e.libelle}
                                                        </option>
                                                    )
                                                })}
                                            </Field>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="row depense-label">
                        <label>Dépense</label>
                    </div>
                    <div className="d-flex">
                        <div className="input-edit" >
                            <div className="row">
                            {/* <div className="euro-symbole">&#8364;</div> */}
                                <Field
                                    className="select-input"
                                    name="depense"
                                    id="depense"
                                    type="text"
                                    innerRef={inputRef}
                                    onChange={(e) => {
                                        onDepenseValueChange(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        handleKeyDown(e)
                                    }}>
                                </Field>
                                <div className="icon-edit" onClick={e => { onEditDepense() }}><i className="fas fa-pencil-alt"></i></div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>

            <div>
                <section className="formula-slider-simulator">
                    <div className="container">
                        <Slider {...settings}>
                            {simulatorValues.resultatsSimulateurRacDTO && moveItemDown(simulatorValues.formuleId, simulatorValues.resultatsSimulateurRacDTO, false).map((f, index) => (
                                <div key={index} className="card">
                                    <div className="card-body">
                                        <div className="simulator-item">
                                            <div className="formule-name">{f.libelleFormule}</div>
                                            <div className="gauge-container">
                                                <div className="svg-container">
                                                    <svg width="250" height="250" viewBox="0 0 140 140">
                                                        <defs>
                                                            <circle id="circle" cx="70" cy="70" r="52" fill="none" pathLength="160" strokeWidth="18" />
                                                        </defs>
                                                        <use className="percent2" href="#circle" stroke="#C6C6C6" />
                                                        <use className={"percent2 " + getFillClasses(f.rembourementGlobal, f.depense).rembClass} href="#circle" stroke="#6AA617" />
                                                        <use className={"percent2 " + getFillClasses(f.rembourementGlobal, f.depense).resteAChargeClass} href="#circle" stroke="#ffffff" /> 
                                                    </svg>
                                                </div>
                                                <div className="remboursement">
                                                    REMBOURSEMENT
                                                </div>

                                                <div className="remboursement-value">
                                                    <span>{f.rembourementGlobal} &#8364;</span>
                                                </div>
                                                <div className="reste-a-charge">
                                                    Reste à charge
                                                </div>
                                                <div className="reste-a-charge-value">
                                                    <span>{f.resteACharge} &#8364;</span>
                                                </div>
                                            </div>
                                            <div className="simulator-item-bottom">
                                                <div className="row">
                                                    <span className="green-square"></span>
                                                    <span className="remboursement-label">Remboursement</span>
                                                </div>
                                                <div className="remboursement-value-item">Sécurité sociale &nbsp;&nbsp; {f.remboursementSecuriteSociale} &#8364;</div>
                                                <div className="remboursement-value-item">Mutuelle MGC &nbsp;&nbsp; {f.remboursementMgc} &#8364;</div>
                                                <div className="row mb-2">
                                                    <span className="gray-square"></span>
                                                    <span className="remboursement-label">Reste à charge</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </section>
            </div>
            {simulatorValues.prestationSousNiveauSoinDTO && (
                <div className="text-detail">
                    { simulatorValues.prestationSousNiveauSoinDTO.title && 
                      <div  ref={ref} className="text-title">
                        { simulatorValues.prestationSousNiveauSoinDTO.title }
                      </div>
                    }

                    <div className="text-description">
                        {simulatorValues.prestationSousNiveauSoinDTO.description}
                    </div>
                </div>
            )}
        </div> : <div>
            <div className='container'>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}>

                    <div className="rac-simulator-block-mobile">
                        {props.showTitle && (
                            <div className="row">
                                <h3 className="mobile-title">
                                    SIMULATEUR DE REMBOURSEMENT
                                </h3>
                            </div>
                        )}
                        <Form className="raz-simulator-block">
                            {
                                simulatorValues.situationProfessionnelleDTO &&
                                <div className="row">
                                    <div className="col-12 form-group select-mobile">
                                        <label className="select-mobile-label">Je suis</label>
                                        <Field
                                            name="situationProfessionnelleId"
                                            component="select"
                                            className="select-input"
                                            onChange={(e) => {
                                                onFormValueChange(e.target.name, e.target.value);
                                            }}>
                                            {simulatorValues.situationProfessionnelleDTO.map(e => {
                                                return (
                                                    <option value={e.id} key={e.id} className="select-style">
                                                        {e.libelle}
                                                    </option>
                                                )
                                            })}
                                        </Field>
                                        <i class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                            }
                            <div>

                                <div className="row">
                                    <div className="col-12 form-group select-mobile">
                                        <label className="select-mobile-label">Type de soin</label>
                                        <Field
                                            name="typeSoin"
                                            component="select"
                                            className="select-input"
                                            onChange={(e) => {
                                                onFormValueChange(e.target.name, e.target.value);
                                            }}>
                                            {props.typeSoins.map(s => {
                                                return (
                                                    <option value={s.value} key={s.value} className="select-style">
                                                        {s.text}
                                                    </option>
                                                )
                                            })}
                                        </Field>
                                        <i class="fas fa-chevron-down"></i>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-12 form-group select-mobile">
                                    <label className="select-mobile-label">Prestation</label>
                                    <Field
                                        name="prestationId"
                                        component="select"
                                        className="select-input"
                                        onChange={(e) => {
                                            onFormValueChange(e.target.name, e.target.value);
                                        }}>
                                        {simulatorValues.prestationDTO && simulatorValues.prestationDTO.map(e => {
                                            return (
                                                <option value={e.id} key={e.id} className="select-style">
                                                    {e.libelle}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                    <i class="fas fa-chevron-down"></i>
                                </div>
                            </div>

                            {simulatorValues.sousNiveauSoinsDTO && simulatorValues.sousNiveauSoinsDTO[0].libelle !== 'x' && (
                                <div className="row">
                                    <div className="col-12 form-group select-mobile">
                                        <label className="select-mobile-label">Sous-niveau de soins</label>
                                        <Field
                                            name="sousNiveauSoinId"
                                            component="select"
                                            className="select-input"
                                            onChange={(e) => {
                                                onFormValueChange(e.target.name, e.target.value);
                                            }}>
                                            {simulatorValues.sousNiveauSoinsDTO && simulatorValues.sousNiveauSoinsDTO.map(e => {
                                                return (
                                                    <option value={e.id} key={e.id} className="select-style">
                                                        {e.libelle}
                                                    </option>
                                                )
                                            })}
                                        </Field>
                                        <i class="fas fa-chevron-down"></i>
                                    </div>
                                </div>

                            )}

                            <div className="row">
                                <div className="col-12 form-group select-mobile">
                                    <label className="select-mobile-label">Dépense</label>
                                    <div className="depense-row">
                                        <Field className="select-input padding-depense"
                                            name="depense"
                                            id="depense"
                                            type="text"
                                            onChange={(e) => {
                                                onDepenseValueChange(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                handleKeyDown(e);
                                            }}
                                            >
                                        </Field>
                                        <div className="icon-edit" onClick={e => { onEditDepense() }}><i className="fas fa-pencil-alt"></i></div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Formik>

                <div>
                    <section className="formula-slider-simulator">
                        <div>
                            <Slider {...settings}>
                                {simulatorValues.resultatsSimulateurRacDTO && moveItemDown(simulatorValues.formuleId, simulatorValues.resultatsSimulateurRacDTO, true).map((f, index) => (
                                    <div key={index} className="card">
                                        <div className="card-body">
                                            <div className="simulator-item">
                                                <div className="formule-name">{f.libelleFormule}</div>
                                                <div className="gauge-container">
                                                    <div className="svg-container">
                                                        <svg width="280" height="280" viewBox="0 0 174 174">
                                                            <defs>
                                                                <circle id="circle" cx="87" cy="87" r="78" fill="none" pathLength="160" strokeWidth="18" />
                                                            </defs>
                                                            <use className="percent2" href="#circle" stroke="#C6C6C6" />
                                                            <use className={"percent2 " + getFillClasses(f.rembourementGlobal, f.depense).rembClass} href="#circle" stroke="#6AA617" />
                                                            <use className={"percent2 " + getFillClasses(f.rembourementGlobal, f.depense).resteAChargeClass} href="#circle" stroke="#ffffff" /> 
                                                        </svg>
                                                    </div>
                                                    <div className="remboursement">
                                                        REMBOURSEMENT
                                                    </div>

                                                    <div className="remboursement-value">
                                                        <span>{f.rembourementGlobal} &#8364;</span>
                                                    </div>
                                                    <div className="reste-a-charge">
                                                        Reste à charge
                                                    </div>
                                                    <div className="reste-a-charge-value">
                                                        <span>{f.resteACharge} &#8364;</span>
                                                    </div>
                                                </div>
                                                <div className="simulator-item-bottom">
                                                    <div className="row">
                                                        <span className="green-square"></span>
                                                        <span className="remboursement-label">Remboursement</span>
                                                    </div>
                                                    <div className="remboursement-value-item">Sécurité sociale &nbsp;&nbsp; {f.remboursementSecuriteSociale} &#8364;</div>
                                                    <div className="remboursement-value-item">Mutuelle MGC &nbsp;&nbsp; {f.remboursementMgc} &#8364;</div>
                                                    <div className="row mb-2">
                                                        <span className="gray-square"></span>
                                                        <span className="remboursement-label">Reste à charge</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </section>
                </div>
                {simulatorValues.prestationSousNiveauSoinDTO && (
                    <div className="text-description">
                        {simulatorValues.prestationSousNiveauSoinDTO.description}
                    </div>
                )}
            </div>
        </div>)

}

export default RacSimulatorView
