import React, { Component } from "react"

class ColorBlock extends Component {
  render() {
    const { content, url } = this.props.data
    return (
      <section className="cibleInformations">       
        {url && url.length > 0 ? (
          <a href={url}>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div
                    className="cibleInformationsText"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div> 
              </div>
            </div>
          </a>
              ) : (
            <div className="container">
              <div className="row">
                <div className="col">
                  <div
                  className="cibleInformationsText"
                  dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div> 
              </div>
            </div>
              )}
      </section>
    )
  }
}
export default ColorBlock
