import React from "react"
import Alert from "react-bootstrap/Alert";
import GAnalyticsService from "../../services/gAnalyticsService"
import { ATInternetTagService } from './../../services/global/ATInternetService';

class ComplementarySended extends React.Component {
    componentDidMount() {
      GAnalyticsService.sendEvent("Devis surcomplémentaire", "Demande de devis", "ADIMUT")
      ATInternetTagService.sendPage({page:'demande_devis',page_chapter1:'mutuelle_prevoyance',page_chapter2:'devis'});

      window.dataLayer.push({
            event: "quotation_form",
            form_name: this.props.quotationform
        });

      window.scrollTo(0, 400)
    }

  render() {
        return (
            <Alert variant="light" className={'alert-surcomplementaire col-lg-10 offset-lg-1 '}>
                <h3>{this.props.title}</h3>
                <p style={{ fontSize: "1rem",  color: "#212529"}}>Nous vous remercions pour cette demande de devis et de l'intérêt que vous portez à la garantie Prévoyance Territoriale.</p>
                <p style={{ fontSize: "1rem",  color: "#212529"}}>Votre demande a bien été prise en compte et vous recevrez l'appel d'un conseiller MGC dans les meilleurs délais selon le créneau de disponibilité choisi.</p>
            </Alert>
        )
    }
}

export default ComplementarySended
