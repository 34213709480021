import React from "react"
import { Formik, Field } from "formik"
import { Container, Row, Col } from "react-bootstrap"
import QuotationAdresse from "./quotationAdresse"
import MgcTextField from "./mgcTextField"
import MgcSelectField from "./mgcSelectField"
import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import DeathBenefitValidation from "./deathBenifitValidation"
import DeathBenefitCapital from "./deathBenefitCapital"
import DeathBenefitBudget from "./deathBenefitBudget"
import DeathBenefitConjoint from "./deathBenefitConjoint"
import Axios from "axios"
import MgcNewDateField from "./mgcNewDateField";
import GAnalyticsService from "../services/gAnalyticsService"
import { ATInternetTagService } from "../services/global/ATInternetService";

class DeathBenefitQuotation extends React.Component {
  state = {
    situationFamiliale: this.props.situationFamiliale,
  }
  modeObseque
  capitaux

  componentDidUpdate(prevProps) {
    if (
      this.props.situationFamiliale !== prevProps.situationFamiliale &&
      this.props.situationFamiliale
    ) {
      this.props.situationFamiliale.forEach(e => {
        e.id = e.libelle
      })
      this.setState({ situationFamiliale: this.props.situationFamiliale })
    }
  }

  constructor(props) {
    super(props)
    this.modeObseque = [
      { id: "INHUMATION", libelle: "Inhumation" },
      { id: "CREMATION", libelle: "Crémation" },
      { id: "NON_DETERMINE", libelle: "Non déterminé" },
    ]
    this.capitaux = [
      { id: "1000", libelle: "1000" },
      { id: "2000", libelle: "2000" },
      { id: "3000", libelle: "3000" },
      { id: "4000", libelle: "4000" },
      { id: "5000", libelle: "5000" },
      { id: "6000", libelle: "6000" },
      { id: "7000", libelle: "7000" },
      { id: "8000", libelle: "8000" },
      { id: "9000", libelle: "9000" },
      { id: "10000", libelle: "10000" },
      { id: "11000", libelle: "11000" },
      { id: "12000", libelle: "12000" },
    ]
  }

  componentDidMount() {
    let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/numero-latins`
    /*        URL = "http://localhost:8080/api/numero-latins"*/
    Axios.get(URL).then(result => {
      this.setState({numeroLatinList : result.data })
    })
    URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/type-voies?size=999&sort=libelle,asc`
    /*        URL = "http://localhost:8080/api/type-voies"*/
    Axios.get(URL).then(result => {
      this.setState({ typeVoieList: result.data })
    })
  }

  render() {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={DeathBenefitValidation}
          onSubmit={(values, actions) => {
            sendMail(values, this.props.changeStatus, this.state.numeroLatinList, this.state.typeVoieList)
            actions.setSubmitting(false)
          }}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <Container className="death-quotation mtop ">
                <h4>MES INFORMATIONS</h4>
                <Row className="">
                  <Col xs={12}>
                    <span>Je suis adhérent MGC ?</span>
                  </Col>
                  <Col xs={12} className="quotation-radio">
                    <MgcRadioToggleButtonGroup
                      id="adherent"
                      error={props.errors.adherent}
                      touched={props.touched.adherent}
                      withoutOffset={true}
                      value={props.values.adherent}
                      dirty={true}
                    >
                      <Field
                        component={MgcRadioToggleButton}
                        name="adherent"
                        id="true"
                        label={"Oui"}
                      />
                      <Field
                        component={MgcRadioToggleButton}
                        name="adherent"
                        id="false"
                        label={"Non"}
                      />
                    </MgcRadioToggleButtonGroup>
                  </Col>
                </Row>
                {props.values.adherent === "true" ? (
                  <Row>
                    <Col xs={12}>
                      <MgcTextField
                        withoutOffset={true}
                        dirty={props.dirty}
                        errors={props.errors}
                        name="numAdherent"
                        placeholder="N° adhérent MGC"
                        className="form-control"
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                        />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row className="">
                  <Col xs={12} className="quotation-radio">
                    <MgcRadioToggleButtonGroup
                      id="civilite"
                      error={props.errors.civilite}
                      touched={props.touched.civilite}
                      withoutOffset={true}
                      value={props.values.civilite}
                      dirty={true}
                    >
                      <Field
                        component={MgcRadioToggleButton}
                        name="civilite"
                        id="Monsieur"
                        label={"Monsieur"}
                      />
                      <Field
                        component={MgcRadioToggleButton}
                        name="civilite"
                        id="Madame"
                        label={"Madame"}
                      />
                    </MgcRadioToggleButtonGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <MgcTextField
                      withoutOffset={true}
                      dirty={props.dirty}
                      errors={props.errors}
                      name="nom"
                      placeholder="Nom"
                      className="form-control"
                      onChange={e => {
                        props.setFieldValue(
                          e.target.name,
                          e.target.value
                        )
                      }}
                    />
                  </Col>
                  <Col xs={12}>
                    <MgcTextField
                      withoutOffset={true}
                      dirty={props.dirty}
                      errors={props.errors}
                      name="prenom"
                      placeholder="Prénom"
                      className="form-control"
                      onChange={e => {
                        props.setFieldValue(
                          e.target.name,
                          e.target.value
                        )
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <span>Date de naissance:</span>
                  </Col>
                  <Col xs={12} className={'margin-bottom-7'}>
{/*                    <MgcDateField
                      withoutOffset={true}
                      dirty={props.dirty}
                      errors={props.errors}
                      name="dateNaissance"
                      className="form-control"
                      setFieldValue={props.setFieldValue}
                    />*/}
                    <MgcNewDateField
                        withoutOffset={true}
                        dirty={props.dirty}
                        errors={props.errors}
                        name="dateNaissance"
                        className="form-control"
                        setFieldValue={props.setFieldValue}
                        values={props.values}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <MgcSelectField
                      dirty={props.dirty}
                      errors={props.errors}
                      name="situationFamiliale"
                      firstOption="- Situation familiale -"
                      className="form-control"
                      table={this.state.situationFamiliale}
                      setFieldValue={props.setFieldValue}
                    />
                  </Col>
                </Row>
                <h4 className="p-top-50">MES COORDONNEES</h4>
                <Row>
                  <Col xs={12}>
                    <QuotationAdresse
                      errors={props.errors}
                      dirty={props.dirty}
                      setFieldValue={props.setFieldValue}
                      values={props.values}
                      setFieldValue={props.setFieldValue}
                      needLibelleOfTypeVoie={false}
                      withoutOffset={true}
                    />
                    <MgcTextField
                      withoutOffset={true}
                      dirty={props.dirty}
                      errors={props.errors}
                      name="telephone1"
                      placeholder="N° de téléphone"
                      className="form-control"
                      onChange={e => {
                        props.setFieldValue(
                          e.target.name,
                          e.target.value
                        )
                      }}                    />
                    <MgcTextField
                      withoutOffset={true}
                      dirty={props.dirty}
                      errors={props.errors}
                      name="telephone2"
                      placeholder="Autre N° de téléphone"
                      className="form-control"
                    />
                  </Col>
                  <Col
                    md={{ span: 9, offset: 1 }}
                    xs={12}
                    className="quotation-information"
                  >
                    <p>
                      En application de l’article L.121-34 du Code de la
                      Consommation, vous avez la possibilité de vous inscrire
                      gratuitement sur la liste d’opposition au démarchage
                      téléphonique. Pour vous inscrire :{" "}
                      <a href="https://www.bloctel.gouv.fr" target="_blank" rel="noopener noreferrer">www.bloctel.gouv.fr</a>
                    </p>
                  </Col>
                  <Col xs={12}>
                    <MgcTextField
                        withoutOffset={true}
                      dirty={props.dirty}
                      errors={props.errors}
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      onChange={e => {
                        props.setFieldValue(
                          e.target.name,
                          e.target.value
                        )
                      }}                    />
                  </Col>
                </Row>
                <h4 className="p-top-50">MES BESOINS</h4>
                <Row className="">
                  <Col xs={12}>
                    <span>Critère de calcul pour une étude chiffrée:</span>
                  </Col>
                  <Col xs={12} className="quotation-radio">
                    <MgcRadioToggleButtonGroup
                      id="critereCalculEtude"
                      error={props.errors.critereCalculEtude}
                      touched={props.touched.critereCalculEtude}
                      withoutOffset={true}
                      dirty={!props.dirty}
                    >
                      <Field
                        component={MgcRadioToggleButton}
                        name="critereCalculEtude"
                        id="capital"
                        label={"Capital souhaité"}
                      />
                      <Field
                          dirty={true}
                        component={MgcRadioToggleButton}
                        name="critereCalculEtude"
                        id="budget"
                        label={"Budget mensuel"}
                      />
                    </MgcRadioToggleButtonGroup>
                  </Col>
                </Row>
                <Row>
                {props.values.critereCalculEtude === "capital" ? (
                    <DeathBenefitCapital
                      tab={this.capitaux}
                      values={props.values}
                      dirty={props.dirty}
                      errors={props.errors}
                      tabMode={this.modeObseque}
                      setFieldValue={props.setFieldValue}
                      withoutOffset={true}
                    />
                    ) : props.values.critereCalculEtude === "budget" ? (
                    <DeathBenefitBudget
                      values={props.values}
                      dirty={props.dirty}
                      errors={props.errors}
                      tabMode={this.modeObseque}
                      setFieldValue={props.setFieldValue}
                      onChange={e => {
                        props.setFieldValue(
                          e.target.name,
                          e.target.value
                        )
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="">
                  <Col xs={12}>
                    <span>Je souhaite bénéficier de l'offre couple ?</span>
                  </Col>
                  <Col xs={12} className="quotation-radio">
                    <MgcRadioToggleButtonGroup
                      id="offreCoupDouble"
                      error={props.errors.offreCoupDouble}
                      touched={props.touched.offreCoupDouble}
                      withoutOffset={true}
                      dirty={!props.dirty}
                    >
                      <Field
                        component={MgcRadioToggleButton}
                        name="offreCoupDouble"
                        id="true"
                        label={"Oui"}
                        dirty={true}
                      />
                      <Field
                        component={MgcRadioToggleButton}
                        name="offreCoupDouble"
                        id="false"
                        label={"Non"}
                        dirty={true}
                      />
                    </MgcRadioToggleButtonGroup>
                  </Col>
                  <Col xs={11}>
                    <span>
                      Les 3 premiers mois sont offerts soit 25% la première
                      année pour le conjoint en cas d’adhésion simultanée (sauf
                      en cas de cotisation unique).
                    </span>
                  </Col>
                </Row>
                {props.values.offreCoupDouble === "true" ? (
                  <DeathBenefitConjoint
                    tabMode={this.modeObseque}
                    tab={this.capitaux}
                    touched={props.touched}
                    values={props.values}
                    dirty={props.dirty}
                    errors={props.errors}
                    setFieldValue={props.setFieldValue}
                  />
                ) : (
                  ""
                )}
                <Row>
                  <Col
                    className="ptop"
                    xs={{ span: 10, offset: 1 }}
                    lg={{ span: 6, offset: 3 }}
                  >
                    <button
                      type="submit"
                      className={` ${
                        !props.isValid || !props.dirty
                          ? "btn-disabled"
                          : "btn-submit"
                      }`}
                    >
                      ENVOYER MA DEMANDE DE DEVIS
                    </button>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        />
      </div>
    )
  }
}
export default DeathBenefitQuotation

let initialValues = {
  nom: "",
  prenom: "",
  dateNaissance: "",
  adherent: "",
  civilite: "",
  critereCalculEtude: "",
  offreCoupDouble: "",
}

function sendMail(value,changeStatus, numeroLatins, libelleTypeVoies) {
/*  let URL = "http://localhost:8080/api/public/devis-capital-obseque"*/
  const typeVoie = libelleTypeVoies.find(ltv => {
    return ltv.id+"" === value.adresseTypeVoie+""
  })
  const numeroLatin = numeroLatins.find(nl => {
    return nl.id+"" === value.adresseNumeroLatin
  })
    const URL = `${
    process.env.GATSBY_MGCGESTION_API_URL
  }/public/devis-capital-obseque`
  Axios.post(
    URL,
    {
      adherent: value.adherent,
      numAdherent: value.numAdherent,
      civilite: value.civilite,
      email: value.email,
      telephone1: value.telephone1,
      telephone2: value.telephone2,
      nom: value.nom,
      prenom: value.prenom,
      dateNaissance: value.dateNaissance,
      situationFamiliale: value.situationFamiliale,
      adresse: {
        typeVoieLibelle: typeVoie ? typeVoie.libelle : '',
        lieuDit: value.adresseLieuDit,
        appartement: value.appartement,
        batiment: value.batiment,
        numero: value.adresseNumero,
        numeroLatin: numeroLatin ? numeroLatin.libelle : '',
        complementAlpha: value.adresseComplementAlpha,
        typeVoie: value.adresseTypeVoie,
        voie: value.adresseVoie,
        adresse4: "",
        codePostal: value.adresseCodePostal,
        ville: value.adresseVille,
        pays: value.adressePays,
      },
      critereCalculEtude: value.critereCalculEtude,
      capital: value.capitalSouhaite,
      modeObseque: value.modeObseque,
      budget: value.budget,
      offreCouple: value.offreCoupDouble,
      civiliteConjoint: value.civiliteConjoint,
      nomConjoint: value.nomConjoint,
      prenomConjoint: value.prenomConjoint,
      dateNaissanceConjoint: value.dateNaissanceConjoint,
      critereCalculEtudeConjoint: value.critereCalculEtudeConjoint,
      capitalConjoint: value.capitalSouhaiteConjoint,
      modeObsequeConjoint: value.modeObsequeConjoint,
      budgetConjoint: value.budgetConjoint,
    }
  ).then(result => {
    GAnalyticsService.sendEvent("Devis prévoyance", "Demande de devis", "MCO")

    ATInternetTagService.sendPage({page:'validation_demande_devis',page_chapter1:'mutuelle_prevoyance',page_chapter2:'devis'});

    var idc = value.nom + value.prenom;
    window.dataLayer.push({
      event: 'infos',
      user_data: {
        user_id: ATInternetTagService.hashTheID(idc),
        user_first_name: value.nom ,
        user_last_name: value.prenom,
        user_mail: value.email,
        user_phone: value.telephone1
      }
    });
/*
    window.dataLayer.push({
      event: "quotation_form",
      form_name: this.props.quotationform
    });
*/
    changeStatus('sended')
  }).catch(()=>{
    changeStatus('failed')
  })
}
