import React from "react";
import PropTypes from 'prop-types';
import FormulaLevel from "./FormulaLevel";
import UrlService from "../services/global/urlService"
import { ATInternetTagService } from '../services/global/ATInternetService';

const FormulaLevelColumn = ({libelle, lien, codeCouleur, prixMensuel, niveauSoins, niveauHospi, niveauOptique, niveauDentaire, style, tns, priceLink, niveauMax, niveauAidesAuditives, niveauAidesAuditivesDisponible, niveauMedecineDouce}) => (
    <div className={`cibleFormulePresentationTableCol ${codeCouleur}`} style={style}>
        <div className={`title ${codeCouleur}`}><a href={UrlService.addSlashToEndOfUrl(lien)} onClick={() => ATInternetTagService.sendNavClick({click:libelle})}>{libelle}</a></div>
        <FormulaLevel  classIcon="icon-drug" level={niveauSoins} tns={tns} link={lien} maxLevel={niveauMax || 5} />
        <FormulaLevel  classIcon="icon-hospital" level={niveauHospi} tns={tns} link={lien} maxLevel={niveauMax || 5} />
        <FormulaLevel  classIcon="far fa-eye" level={niveauOptique} tns={tns} link={lien} maxLevel={niveauMax || 5} />
        <FormulaLevel  classIcon="icon-tooth" level={niveauDentaire} tns={tns} link={lien} maxLevel={niveauMax || 5} />
        <FormulaLevel  classIcon="icon-hospital" level={niveauMedecineDouce} tns={tns} link={lien} maxLevel={niveauMax || 5} isSvgIcon="true"/>
        { niveauAidesAuditivesDisponible && <FormulaLevel  classIcon="fas fa-assistive-listening-systems" level={niveauAidesAuditives} tns={tns} link={lien} maxLevel={niveauMax || 5} />}
        <div className={`title ${codeCouleur}`} style={{backgroundColor: codeCouleur}}><a href={UrlService.addSlashToEndOfUrl(priceLink) || ''}>à partir de {prixMensuel ? prixMensuel.toLocaleString(undefined, { minimumFractionDigits: 2 }): "-"}€/mois<sup>(1)</sup></a></div>
    </div>
);

FormulaLevelColumn.propTypes = {
    libelle: PropTypes.string.isRequired,
    lien: PropTypes.string,
    codeCouleur: PropTypes.string.isRequired,
    prixMensuel: PropTypes.number.isRequired,
    niveauSoins: PropTypes.number.isRequired,
    niveauHospi: PropTypes.number.isRequired,
    niveauOptique: PropTypes.number.isRequired,
    niveauDentaire: PropTypes.number.isRequired,
    niveauMedecineDouce: PropTypes.number.isRequired,
    niveauMax: PropTypes.number.isRequired,
    niveauAidesAuditives: PropTypes.number.isRequired,
    niveauAidesAuditivesDisponible: PropTypes.bool
  };

export default FormulaLevelColumn;
