import React, { Component } from "react"
import Slider from "react-slick";
import CibleFormuleNextArrow from "../../components/cibleFormulesSliderNextArrow"
import CibleFormulePrevArrow from "../../components/cibleFormulesSliderPrevArrow"
import Blocks from "./blocks";
import { isMobile } from 'react-device-detect';
import { ATInternetTagService } from './../../services/global/ATInternetService';

class CarouselFaqBlock extends Component {
    data;
    settings;
    state= {
        slidesToShowValues: 1,
        slidesToScrollValues: 1
    }
    componentDidMount() {
        let slide = isMobile ? 1 : (this.data.length < 3 ? this.data.length : 3);
        this.setState({slidesToShowValues: slide});
        this.setState({slidesToScrollValues: slide});
    }

    componentWillMount() {
        this.data = [];
        if (this.props.data.faq0 !== null) { this.data.push(this.props.data.faq0); }
        if (this.props.data.faq1 !== null) { this.data.push(this.props.data.faq1); }
        if (this.props.data.faq2 !== null) { this.data.push(this.props.data.faq2); }
        if (this.props.data.faq3 !== null) { this.data.push(this.props.data.faq3); }
        if (this.props.data.faq4 !== null) { this.data.push(this.props.data.faq4); }
        if (this.props.data.faq5 !== null) { this.data.push(this.props.data.faq5); }
        if (this.props.data.faq6 !== null) { this.data.push(this.props.data.faq6); }
        if (this.props.data.faq7 !== null) { this.data.push(this.props.data.faq7); }
        if (this.props.data.faq8 !== null) { this.data.push(this.props.data.faq8); }
    }
    render() {
        const settings = {
            arrows: isMobile ? false : true,
            nextArrow: <CibleFormuleNextArrow />,
            prevArrow: <CibleFormulePrevArrow />,
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: isMobile ? 5000 : 8000,
            pauseOnHover: true,
            slidesToShow: this.state.slidesToShowValues,
            slidesToScroll: this.state.slidesToScrollValues,
            className: 'homeQuestionsSlider'
        };
        return (
            <section key={'carousel' + Blocks.id} className="homeQuestions">
                <div className="container ptl pbl">
                    <div className="row">
                        <div className="col">
                            <h3 className="tac">Questions fréquentes</h3>
                        </div>
                    </div>
                    <Slider {...settings}>
                        {this.data.map((faq) => (
                            <div key={'slide' + Blocks.id} className="card">
                                <div className="card-body">
                                    <h4>{faq.title}</h4>
                                    <p>{faq.description.length > 90 ? (
                                        faq.description.substr(0, faq.description.indexOf(' ', 90)) + '...'
                                    ) : faq.description}</p>
                                    <a href={'/faq/' + faq.url} onClick={() => ATInternetTagService.sendNavClick({click:faq.title,click_chapter2:'questions_frequentes'})}>En savoir plus</a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        )
    }
}

export default CarouselFaqBlock