import React from "react"
import { Formik, Field } from "formik"
import { Container, Row, Col } from "react-bootstrap"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import devisTypeValidation from "./devisTypeValidation"
import Axios from "axios"
import GAnalyticsService from "../services/gAnalyticsService"
import MgcRadioToggleButtonGroupV2 from "./global/mgcRadioToggleButtonGroupV2"
import MgcRadioToggleButtonV2 from "./global/mgcRadioToggleButtonV2"
import MgcTextFieldV2 from "./global/mgcTextFieldV2"
import MgcNewDateFieldV2 from "./global/mgcNewDateFieldV2"
import { scrollToFirstError } from "../services/global/formErrorsService"
import {ATInternetTagService} from "../services/global/ATInternetService";

class PrevoyanceTerritorialeQuotation extends React.Component {
  state = {
    errorAcceptTel:false,
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      values: props,
      errorValidationOnSubmit: false
    }

  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  handleSubmit = (values, actions) => {
    this.setState({errorAcceptTel:true})
    actions.setSubmitting(true)
    sendMail(values, this.props.changeStatus)
    actions.setSubmitting(false)
  }


  scrollTOError(props){
    if (typeof window !== "undefined" && !props.isValid && !props.isValidating && !props.isSubmitting){
     scrollToFirstError(props.errors)
    }
  }

  render() {

    const {
      submitCount
    } = this.props

    return (
      <div>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={devisTypeValidation}
           onSubmit={(values, actions) => {
            console.log('onSubmit onSubmit onSubmit onSubmit');    
            this.handleSubmit(values, actions)
          }}
          ref={this.formRef}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              {
                props.errors && this.scrollTOError(props)
              }
              <Container className="death-quotation mtop ">
                <h4>MES INFORMATIONS</h4>
                <Row className="">
                  <Col xs={12} className="quotation-radio">
                  <MgcRadioToggleButtonGroupV2
                      id="adherent"
                      value={props.values.adherent}
                      touched={true}
                      label="Je suis adhérent MGC ? *"
                      withoutOffset={true}
                      isErrorSubmitting={false}
                      error={props.errors.adherent}
                      dirty={true}
                    >
                      <Field
                        component={MgcRadioToggleButtonV2}
                        name="adherent"
                        id="true"
                        label="Oui"
                      />
                      <Field
                        component={MgcRadioToggleButtonV2}
                        name="adherent"
                        id="false"
                        label="Non"
                      />
                    </MgcRadioToggleButtonGroupV2>
                  </Col>
                </Row>
                {props.values.adherent === "true" ? (
                  <Row>
                    <Col xs={12}>
                      <MgcTextFieldV2
                        dirty={props.dirty}
                        errors={props.errors}
                        name="numAdherent"
                        type="number"
                        placeholder=""
                        label={"N° adhérent MGC"}
                        maxLength={30}
                        withoutOffset={true}
                        isErrorSubmitting={true}
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row className="">
                  <Col xs={12} className="quotation-radio">
                  <MgcRadioToggleButtonGroupV2
                      id="civilite"
                      value={props.values.civilite}
                      error={props.errors.civilite}
                      touched={props.touched.civilite}
                      label="Civilité *"
                      withoutOffset={true}
                      isErrorSubmitting={false}
                    >
                      <Field
                        component={MgcRadioToggleButtonV2}
                        name="civilite"
                        id="Monsieur"
                        label="Monsieur"
                      />
                      <Field
                        component={MgcRadioToggleButtonV2}
                        name="civilite"
                        id="Madame"
                        label="Madame"
                      />
                    </MgcRadioToggleButtonGroupV2>

                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                  <MgcTextFieldV2
                        dirty={props.dirty}
                        errors={props.errors}
                        name="nom"
                        placeholder="EX : DURAND"
                        label={"Nom *"}
                        isErrorSubmitting={true}
                        withoutOffset={true}
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                      />
                  </Col>
                  <Col xs={12}>
                  <MgcTextFieldV2
                        dirty={props.dirty}
                        errors={props.errors}
                        name="prenom"
                        placeholder="Ex : Jean"
                        label={"Prénom *"}
                        isErrorSubmitting={true}
                        withoutOffset={true}
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={'margin-bottom-7'}>                    
                    <MgcNewDateFieldV2
                      dirty={props.dirty}
                      errors={props.errors}
                      name="dateNaissance"
                      label="Date de naissance *"
                      setFieldValue={props.setFieldValue}
                      values={props.values}
                      isErrorSubmitting={true}
                      withoutOffset={true}
                      resetErrorsAfterSubmitWithErrors={() => {}}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                  <MgcTextFieldV2
                        dirty={props.dirty}
                        errors={props.errors}
                        name="telephone1"
                        placeholder="Ex : 0612234562"
                        isErrorSubmitting={true}
                        label="Téléphone"
                        withoutOffset={true}
                        type="number"
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                      />
                  </Col>
                  <label className="mention-quot-tel">
                        Le recueil de mon numéro de téléphone a pour seule finalité de permettre au conseiller de me contacter pour obtenir des informations quant à ma situation et mes besoins, en vue de l’établissement d’un devis. 
                        En communiquant mon numéro de téléphone, j’accepte d’être contacté(e) par la mutuelle MGC dans le cadre de ma demande de devis.
                        En application de l’article L.121-34 du Code de la Consommation, vous avez la possibilité de vous inscrire gratuitement sur la liste d’opposition au démarchage téléphonique. Pour vous inscrire :
                    <a href="https://www.bloctel.gouv.fr" target="_blank" rel="noopener noreferrer">www.bloctel.gouv.fr</a>
                  </label>
                </Row>
                <p></p><h4>CRENEAU DE DISPONIBILITE</h4><p></p>
                <Row className="">
                  <Col xs={12} className="quotation-radio">
                  <MgcRadioToggleButtonGroupV2
                      id="addisponibiliteherent"
                      value={props.values.disponibilite}
                      error={props.errors.disponibilite}
                      touched={props.touched.disponibilite}
                      label="Je choisis un créneau pour être recontacté *"
                      withoutOffset={true}
                      isErrorSubmitting={true}
                    >
                     <Field
                        component={MgcRadioToggleButton}
                        name="disponibilite"
                        id="Matin"
                        label={"Matin"}
                      />
                      <Field
                        component={MgcRadioToggleButton}
                        name="disponibilite"
                        id="Midi"
                        label={"Midi"}
                      />
                      <Field
                        component={MgcRadioToggleButton}
                        name="disponibilite"
                        id="Après-midi"
                        label={"Après-midi"}
                      />
                    </MgcRadioToggleButtonGroupV2>
                  </Col>
                </Row>
                <p></p><h4>POUR PRÉPARER MON ENTRETIEN</h4><p></p>
                <Row>
                <Col
                    md={{ span: 11, offset: 0 }}
                    xs={12}
                    className="quotation-information"
                  >
                    <p>
                    Munissez-vous de votre dernière fiche de paie afin de communiquer au conseiller les éléments pour calculer au mieux vos pertes de revenus.
                    </p>
                  </Col>
                  <Col
                    className="ptop"
                    xs={{ span: 10, offset: 1 }}
                    lg={{ span: 6, offset: 3 }}
                  >
                    <button
                      type="submit"
                      className={` ${
                        !props.isValid || !props.dirty ? "btn-submit" : "btn-submit"
                      }`}
                      >
                      JE SOUHAITE ÊTRE RAPPELÉ
                    </button>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        />
      </div>
    )
  }
}
export default PrevoyanceTerritorialeQuotation

let initialValues = {
  nom: "",
  prenom: "",
  dateNaissance: "",
  adherent: "",
  civilite: "",
  accepteTel: false,
  telephone1: "",
}

function sendMail(value,changeStatus) {
    const URL = `${
    process.env.GATSBY_MGCGESTION_API_URL
  }/public/devis-prevoyance-territoriale`
    Axios.post(
    URL,
    {
      adherent: value.adherent,
      numAdherent: value.numAdherent,
      civilite: value.civilite,
      telephone1: value.telephone1,
      nom: value.nom,
      prenom: value.prenom,
      dateNaissance: value.dateNaissance,
      disponibilite: value.disponibilite,
    }
  ).then(result => {
    GAnalyticsService.sendEvent("Devis prévoyance Territoriale", "Demande de devis", "MCO")

      var idc = value.nom + value.prenom;
      window.dataLayer.push({
        event: 'infos',
        user_data: {
          user_id: ATInternetTagService.hashTheID(idc),
          user_first_name: value.nom,
          user_last_name: value.prenom,
          user_mail: '',
          user_phone: value.telephone1
        }
      });

    changeStatus('sended')
  }).catch(()=>{
    changeStatus('failed')
  })
}
