import React, { useState } from "react"
import { Accordion, Card, Button } from "react-bootstrap"

function AccordionBlock({ data }) {
  const [accordionItemOpen, setAccordionItemOpen] = useState(null)

  function handleAccordionItemChange(id) {
    if (accordionItemOpen === id) {
      setAccordionItemOpen(null)
    } else {
      setAccordionItemOpen(id)
    }
  }

  return (
    <section className="accordion-block">
      <div className="container">
        <div className="row">
          <div className="col">
            <Accordion>
              {data.items &&
                data.items.map(item => (
                  <Card key={`accordion-block-item-${item.id}`}>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <div
                        as={Button}
                        variant="link"
                        className="faqAccordionSectionTitle"
                        onClick={() => handleAccordionItemChange(item.id)}
                      >
                        <div
                          className="w-100"
                          dangerouslySetInnerHTML={{ __html: item.header }}
                        />
                        <i
                          className={
                            accordionItemOpen === item.id
                              ? "faqAccordionSectionIcon fas fa-minus-circle"
                              : "faqAccordionSectionIcon fas fa-plus-circle"
                          }
                        />
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={item.id}>
                      <Card.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AccordionBlock
