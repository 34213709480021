import React from "react"
import {Row, Col} from "react-bootstrap"
import MgcRadioToggleButton from "../mgcRadioToggleButton"
import {Field} from "formik"
import Alert from 'react-bootstrap/Alert'
import MgcRadioToggleButtonGroup from "../mgcRadioToggleButtonGroup"

class ComplementaryQuotationRequirements extends React.Component {
    render() {
        const {errors, touched, values} = this.props
        return (
            <>
                <Row>
                    <Col xs={{span: 10, offset: 1}}>
                        <h4 className="p-top-50">Mes besoins</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 10, offset: 1}}>
                        <Alert variant='warning'>
                            Dépenses non ou peu prises en charge par mon assurance maladie
                            obligatoire et ma mutuelle obligatoire
                        </Alert>


                    </Col>
                </Row>
                <Row className="question-y-n">
                    <Col xs={{span: 11, offset: 1}}>Dépassements d'honoraires</Col>
                    <Col xs={12} className="quotation-radio">
                        <MgcRadioToggleButtonGroup
                            id="depassementHonoraires"
                            error={errors.depassementHonoraires}
                            touched={touched.depassementHonoraires}
                            value={values.depassementHonoraires}
                            dirty={true}
                        >
                            <Field
                                component={MgcRadioToggleButton}
                                name="depassementHonoraires"
                                id="true"
                                label={"Oui"}
                            />
                            <Field
                                component={MgcRadioToggleButton}
                                name="depassementHonoraires"
                                id="false"
                                label={"Non"}
                            />
                        </MgcRadioToggleButtonGroup>
                    </Col>
                </Row>

                <Row className="question-y-n">
                    <Col xs={{span: 10, offset: 1}}>Optique</Col>
                    <Col xs={12} className="quotation-radio">
                        <MgcRadioToggleButtonGroup
                            id="optique"
                            error={errors.optique}
                            touched={touched.optique}
                            value={values.optique}
                            dirty={true}
                        >
                            <Field
                                component={MgcRadioToggleButton}
                                name="optique"
                                id="true"
                                label={"Oui"}
                            />
                            <Field
                                component={MgcRadioToggleButton}
                                name="optique"
                                id="false"
                                label={"Non"}
                            />
                        </MgcRadioToggleButtonGroup>
                    </Col>
                </Row>

                <Row className="question-y-n">
                    <Col xs={{span: 11, offset: 1}}>Dentaire</Col>
                    <Col xs={12} className="quotation-radio">
                        <MgcRadioToggleButtonGroup
                            id="dentaire"
                            error={errors.dentaire}
                            touched={touched.dentaire}
                            value={values.dentaire}
                            dirty={true}
                        >
                            <Field
                                component={MgcRadioToggleButton}
                                name="dentaire"
                                id="true"
                                label={"Oui"}
                            />
                            <Field
                                component={MgcRadioToggleButton}
                                name="dentaire"
                                id="false"
                                label={"Non"}
                            />
                        </MgcRadioToggleButtonGroup>
                    </Col>
                </Row>

                <Row className="question-y-n">
                    <Col xs={{span: 11, offset: 1}}>Hospitalisation</Col>
                    <Col xs={12} className="quotation-radio">
                        <MgcRadioToggleButtonGroup
                            id="hospitalisation"
                            error={errors.hospitalisation}
                            touched={touched.hospitalisation}
                            value={values.hospitalisation}
                            dirty={true}
                        >
                            <Field
                                component={MgcRadioToggleButton}
                                name="hospitalisation"
                                id="true"
                                label={"Oui"}
                            />
                            <Field
                                component={MgcRadioToggleButton}
                                name="hospitalisation"
                                id="false"
                                label={"Non"}
                            />
                        </MgcRadioToggleButtonGroup>
                    </Col>
                </Row>

                <Row className="question-y-n">
                    <Col xs={{span: 11, offset: 1}}>Autres</Col>
                    <Col xs={12} className="quotation-radio">
                        <MgcRadioToggleButtonGroup
                            id="autres"
                            error={errors.autres}
                            touched={touched.autres}
                            value={values.autres}
                            dirty={true}
                        >
                            <Field
                                component={MgcRadioToggleButton}
                                name="autres"
                                id="true"
                                label={"Oui"}
                            />
                            <Field
                                component={MgcRadioToggleButton}
                                name="autres"
                                id="false"
                                label={"Non"}
                            />
                        </MgcRadioToggleButtonGroup>
                    </Col>
                </Row>
            </>
        )
    }
}

export default ComplementaryQuotationRequirements
