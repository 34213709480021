import React from "react"
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import MgcSelectField from "./mgcSelectField"
import informationIcon from "../../src/images/icon-information.png"
import MgcTextField from "./mgcTextField"

class DeathBenefitBudget extends React.Component {
  render() {
    const {
      values,
      dirty,
      errors,
      tabMode,
      conjoint,
      setFieldValue,
      onChange
    } = this.props
    return (
      <>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">
              Indiquez le budget que vous souhaitez consacrer à votre solution
              d'assurance obsèques, à partir de 5€ et jusqu'à 200€.
            </Tooltip>
          }
        >
          <img className="textfield-info" src={informationIcon} />
        </OverlayTrigger>
        <Col xs={12} md={12}>
          <MgcTextField
            withoutOffset={true}
            dirty={true}
            errors={errors}
            name={conjoint ? "budgetConjoint" : "budget"}
            placeholder="- Budget mensuel -"
            className="form-control"
            setFieldValue={setFieldValue}
            onChange={onChange}
            displayError={true}
          />



        </Col>
        <Col xs={12}>
          <MgcSelectField
            dirty={true}
            errors={errors}
            name={conjoint ? "modeObsequeConjoint" : "modeObseque"}
            firstOption="- Mode d'obsèques envisagé -"
            className="form-control"
            table={tabMode}
            setFieldValue={setFieldValue}
            withoutOffset={true}
          />
        </Col>
      </>
    )
  }
}
export default DeathBenefitBudget
