import React from "react"
import { Formik, Field } from "formik"
import { Container, Row, Col } from "react-bootstrap"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import devisTypeValidation from "./devisTypeValidation"
import Axios from "axios"
import GAnalyticsService from "../services/gAnalyticsService"
import MgcRadioToggleButtonGroupV2 from "./global/mgcRadioToggleButtonGroupV2"
import MgcRadioToggleButtonV2 from "./global/mgcRadioToggleButtonV2"
import MgcTextFieldV2 from "./global/mgcTextFieldV2"
import MgcNewDateFieldV2 from "./global/mgcNewDateFieldV2"
import { scrollToFirstError } from "../services/global/formErrorsService"
import { ATInternetTagService } from "../services/global/ATInternetService";


class PrevoyanceTnsQuotation extends React.Component {
  state = {
    errorAcceptTel:false,
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      values: props,
      errorValidationOnSubmit: false
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  handleSubmit = (values, actions) => {
    this.setState({errorAcceptTel:true})
    actions.setSubmitting(true)
    sendMail(values, this.props.changeStatus)
    actions.setSubmitting(false)
  }

  scrollTOError(props){
    if (typeof window !== "undefined" && !props.isValid && !props.isValidating && !props.isSubmitting){
     scrollToFirstError(props.errors)
    }
  }

  render() {

    const {
      submitCount
    } = this.props

    return (
      <div>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={devisTypeValidation}
          onSubmit={(values, actions) => {
            console.log('onSubmit onSubmit onSubmit onSubmit');    
            this.handleSubmit(values, actions)
          }}
          ref={this.formRef}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              {
                props.errors && this.scrollTOError(props)
              }
              <Container className="death-quotation mtop ">
                <h4>MES INFORMATIONS</h4>
                <label className="mb-3">Toutes les informations suivies d’un * sont indispensables pour permettre à la mutuelle MGC de me contacter en vue d’établir un devis.</label>
                <Row className="">
                  <Col xs={12} className="quotation-radio">
                  <MgcRadioToggleButtonGroupV2
                      id="adherent"
                      value={props.values.adherent}
                      touched={true}
                      label="Je suis adhérent MGC ? *"
                      withoutOffset={true}
                      isErrorSubmitting={false}
                      error={props.errors.adherent}
                      dirty={true}
                    >
                      <Field
                        component={MgcRadioToggleButtonV2}
                        name="adherent"
                        id="true"
                        label="Oui"
                      />
                      <Field
                        component={MgcRadioToggleButtonV2}
                        name="adherent"
                        id="false"
                        label="Non"
                      />
                    </MgcRadioToggleButtonGroupV2>
                  </Col>
                </Row>
                {props.values.adherent === "true" ? (
                  <Row>
                    <Col xs={12}>
                      <MgcTextFieldV2
                        dirty={props.dirty}
                        errors={props.errors}
                        name="numAdherent"
                        placeholder=""
                        label={"N° adhérent (figurant sur ma carte de tiers payant)"}
                        maxLength={10}
                        withoutOffset={true}
                        isErrorSubmitting={true}
                        type="number"
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row className="">
                  <Col xs={12} className="quotation-radio">
                  <MgcRadioToggleButtonGroupV2
                      id="civilite"
                      value={props.values.civilite}
                      error={props.errors.civilite}
                      touched={props.touched.civilite}
                      label="Civilité *"
                      withoutOffset={true}
                      isErrorSubmitting={false}
                    >
                      <Field
                        component={MgcRadioToggleButtonV2}
                        name="civilite"
                        id="Monsieur"
                        label="Monsieur"
                      />
                      <Field
                        component={MgcRadioToggleButtonV2}
                        name="civilite"
                        id="Madame"
                        label="Madame"
                      />
                    </MgcRadioToggleButtonGroupV2>

                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                  <MgcTextFieldV2
                        dirty={props.dirty}
                        errors={props.errors}
                        name="nom"
                        placeholder="EX : DURAND"
                        label={"Nom *"}
                        isErrorSubmitting={true}
                        withoutOffset={true}
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                      />
                  </Col>
                  <Col xs={12}>
                  <MgcTextFieldV2
                        dirty={props.dirty}
                        errors={props.errors}
                        name="prenom"
                        placeholder="Ex : Jean"
                        label={"Prénom *"}
                        isErrorSubmitting={true}
                        withoutOffset={true}
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={'margin-bottom-7'}>                    
                    <MgcNewDateFieldV2
                      dirty={props.dirty}
                      errors={props.errors}
                      name="dateNaissance"
                      label="Date de naissance *"
                      setFieldValue={props.setFieldValue}
                      values={props.values}
                      isErrorSubmitting={true}
                      withoutOffset={true}
                      resetErrorsAfterSubmitWithErrors={() => {}}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                  <MgcTextFieldV2
                        dirty={props.dirty}
                        errors={props.errors}
                        name="telephone1"
                        maxLength={10}
                        placeholder="Ex : 0612234562"
                        label="Téléphone"
                        isErrorSubmitting={true}
                        withoutOffset={true}
                        type="number"
                        onChange={e => {
                          props.setFieldValue(
                            e.target.name,
                            e.target.value
                          )
                        }}
                      />
                      <label className="mention-quot-tel">
                        Le recueil de mon numéro de téléphone a pour seule finalité de permettre au conseiller de me contacter pour obtenir des informations quant à ma situation et mes besoins, en vue de l’établissement d’un devis. 
                        En communiquant mon numéro de téléphone, j’accepte d’être contacté(e) par la mutuelle MGC dans le cadre de ma demande de devis.
                        En application de l’article L.121-34 du Code de la Consommation, vous avez la possibilité de vous inscrire gratuitement sur la liste d’opposition au démarchage téléphonique. Pour vous inscrire :
                        <a href="https://www.bloctel.gouv.fr" target="_blank" rel="noopener noreferrer">www.bloctel.gouv.fr</a>
                      </label>
                  </Col>
                </Row>
                <p></p><h4>CRENEAU DE DISPONIBILITE</h4><p></p>
                <Row className="">
                  <Col xs={12} className="quotation-radio">
                  <MgcRadioToggleButtonGroupV2
                      id="addisponibiliteherent"
                      value={props.values.disponibilite}
                      error={props.errors.disponibilite}
                      touched={props.touched.disponibilite}
                      label="Je choisis un créneau pour être recontacté"
                      withoutOffset={true}
                      isErrorSubmitting={true}
                    >
                     <Field
                        component={MgcRadioToggleButton}
                        name="disponibilite"
                        id="Matin"
                        label={"Matin"}
                      />
                      <Field
                        component={MgcRadioToggleButton}
                        name="disponibilite"
                        id="Midi"
                        label={"Midi"}
                      />
                      <Field
                        component={MgcRadioToggleButton}
                        name="disponibilite"
                        id="Après-midi"
                        label={"Après-midi"}
                      />
                    </MgcRadioToggleButtonGroupV2>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="ptop"
                    xs={{ span: 10, offset: 1 }}
                    lg={{ span: 6, offset: 3 }}
                  >
                    <button
                      type="submit"
                      className={` ${
                        !props.isValid || !props.dirty ? "btn-submit" : "btn-submit"
                      }`}
                      >
                      JE SOUHAITE ÊTRE RAPPELÉ
                    </button>
                  </Col>
                </Row>
              </Container>
            </form>

          )}
        />
                            <div className={"col-md-8 offset-md-2 col-10 offset-1 mention-quot-wizard"}>
                            <p></p>
                            <b>Assureur : </b><br/>
                            <p>
                              UNMI – Union Nationale Mutualiste Interprofessionnelle, union soumise aux dispositions du Livre II du Code de la Mutualité, SIREN n°784718207 dont le Siège social est situé 50 avenue Daumesnil 75012 Paris.
                            </p>
                            <p></p>
                            <b>Distributeur : </b><br/>
                            <p>
                              Mutuelle Générale des Cheminots (MGC), régie par le code de la Mutualité et soumise aux dispositions de son Livre II, SIREN n° 775 678 550, dont le Siège social est situé 2 et 4 place de l’Abbé G. Hénocque 75013 Paris, et au contrôle de l’Autorité de Contrôle Prudentiel et de Résolution, ACPR, située 4 Place de Budapest - CS 92459 - 75436 Paris Cedex 09.                            </p>
                        </div>
      </div>

    )
  }
}
export default PrevoyanceTnsQuotation

let initialValues = {
  nom: "",
  prenom: "",
  dateNaissance: "",
  adherent: "",
  civilite: "",
  accepteTel: false,
  telephone1: "",
}

function sendMail(value,changeStatus) {
    const URL = `${
    process.env.GATSBY_MGCGESTION_API_URL
  }/public/devis-prevoyance-tns`
   Axios.post(
    URL,
    {
      adherent: value.adherent,
      numAdherent: value.numAdherent,
      civilite: value.civilite,
      telephone1: value.telephone1,
      nom: value.nom,
      prenom: value.prenom,
      dateNaissance: value.dateNaissance,
      disponibilite: value.disponibilite,
    }
  ).then(result => {
    GAnalyticsService.sendEvent("Devis Orizen", "Demande de devis", "MCO")

    var idc = value.nom + value.prenom;
    window.dataLayer.push({
           event: 'infos',
           user_data: {
               user_id: ATInternetTagService.hashTheID(idc),
               user_first_name: value.nom ,
               user_last_name: value.prenom,
               user_mail: 'idc',
               user_phone: value.telephone1
           }
    });


    changeStatus('sended')
  }).catch(()=>{
    changeStatus('failed')
  })
}
