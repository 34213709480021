import React, { Component } from "react"
import { Col } from 'react-bootstrap'
import PrevoyanceTnsQuotation from "../../components/prevoyanceTnsQuotation"
import PrevoyanceTnsComplementarySended from "../../components/prevoyanceTnsComplementarySended";
import ComplementaryFailed from "../../components/complementaryQuotation/complementaryFailed";

class PrevoyanceTns extends Component {

  componentDidMount() {
  }

  constructor(props) {
    super(props);
    this.state = {
        situationFamiliale: [{id: 1, libelle:''},{ id:2, libelle:''}],
        status: 'start'
    };
  }

  changeStatus = val =>{
    this.setState({status: val})
  }

  render() {
    return (
        <section className="quotation-body quotation-complementary">
            <div className="container-quotation">
                <div className="quotation-form col-xs-12">
                    <div>
                    <Col md={{ span: 10, offset: 1 }} xs={12}>
                            { this.state.status === 'start' &&
                            <PrevoyanceTnsQuotation situationFamiliale={this.state.situationFamiliale} changeStatus={this.changeStatus}/>
                            }
                            { this.state.status === 'sended' &&
                            <PrevoyanceTnsComplementarySended title={'VOTRE DEMANDE DE RAPPEL A ÉTÉ PRISE EN COMPTE !'} quotationform={this.props.title}/>
                            }
                            { this.state.status === 'failed' &&
                            <ComplementaryFailed quotationform={this.props.title}/>
                            }
                        </Col>

                    </div>
                </div>
            </div>
    </section>
    )
  }
}

export default PrevoyanceTns
