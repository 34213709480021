import React from "react";

class OneImageBlock extends React.Component {

    render() {
        const {
           media,
            data
        } = this.props
        const divStyle = {
            background: 'white',
            backgroundImage: 'url(' + media[data.media.id] + ')',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
        };
        return (
           <div style={divStyle} className={'block-one-image'}/>
        )
    }
}


export  default OneImageBlock;
            /*<section className="block-one-image">
                <img src={media[data.media.id]}/>
            </section>*/