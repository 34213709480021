import React, { Component } from "react"
import SimpleBlock from "./simpleBlock"
import FourElementPinkBlock from "./fourElementPinkBlock"
import ThreeBlockNumber from "./threeBlockNumber"
import CibleBlock from "./cibleBlock";
import ColorBlock from "./colorBlock";
import FourTabBlock from "./fourTabBlock";
import FormulaHeaderBlock from "./formulaHeaderBlock"
import GammeFormulasBlock from "./gammeFormulasBlock"
import CarouselFaqBlock from "./carouselFaqBlock"
import HeaderFormulaBlock from "./headerFormulaBlock"
import TwoButtonQuotation from './twoButtonQuotation'
import TextWithActualitiesBlock from './textWithActualitiesBlock';
import ThreeIconBlock from './threeIconBlock';
import DeathBenefit from './deathBenefit';
import PrevoyanceTerritoriale from './prevoyanceTerritoriale';
import PrevoyanceTns from './prevoyanceTns';
import Orizen from './orizen';
import ComplementaryQuotationBlock from "./complementaryQuotationBlock";
import ContactBlock from "./contactBlock"
import OneImageBlock from "./oneImageBlock"
import AgenciesBlock from "./agenciesBlock";
import PromotionalOfferMobile from "./promotionalOfferMobile";
import OptoutAtInternetBlock from "./optoutAtInternetBlock";
import RazSimulatorBlock from "./razSimulatorBlock";
import AccordionBlock from "./AccordionBlock";
class Blocks extends Component {

  blocks = {}
  media = {};
  title = ''

  componentWillMount() {
    this.blocks = this.props.data;
    this.media = this.props.media;
    this.title = this.props.title;
  }

  render() {
    return (
      <div>
        {(this.blocks.map(block => {
          switch (block.__typename) {
            case "jhipster_SimpleBlockDTO":
              return <SimpleBlock key={block.id} data={block} media={this.media}></SimpleBlock>
            case "jhipster_FourElementPinkBlockDTO":
              return <FourElementPinkBlock key={block.id} data={block} media={this.media}></FourElementPinkBlock>
            case "jhipster_ThreeBlockNumberDTO":
              return <ThreeBlockNumber key={block.id} data={block} media={this.media}></ThreeBlockNumber>
            case "jhipster_CibleBlockDTO":
              return <CibleBlock key={block.id} data={block} media={this.media} landing={this.props.landing}></CibleBlock>
            case "jhipster_ColorBlockDTO":
              return <ColorBlock key={block.id} data={block} media={this.media}></ColorBlock>
            case "jhipster_FourTabBlockDTO":
              return <FourTabBlock key={block.id} data={block} media={this.media}></FourTabBlock>
            case "jhipster_FormulaHeaderBlockDTO":
              return <FormulaHeaderBlock key={block.id} data={block} media={this.media}></FormulaHeaderBlock>
            case "jhipster_GammeFormulasBlockDTO":
              return <GammeFormulasBlock key={block.id} data={block} media={this.media}></GammeFormulasBlock>
            case "jhipster_CarouselFaqBlockDTO":
              return <CarouselFaqBlock key={block.id} data={block} media={this.media}></CarouselFaqBlock>
            case "jhipster_TwoButtonQuotationBlockDTO":
              return <TwoButtonQuotation key={block.id} ></TwoButtonQuotation>
            case "jhipster_HeaderFormulaBlockDTO":
              return <HeaderFormulaBlock key={block.id} data={block} media={this.media}/>
              case "jhipster_textWithActualitiesBlockDTO":
              return <TextWithActualitiesBlock key={block.id} data={block} media={this.media}/>
              case "jhipster_ThreeIconBlockDTO":
              return <ThreeIconBlock key={block.id} data={block} media={this.media}/>
            case "jhipster_DeathBenefitBlockDTO":
              return <DeathBenefit key={block.id} data={block} media={this.media} title={this.title}/>
            case "jhipster_PrevoyanceTerritorialeBlockDTO":
              return <PrevoyanceTerritoriale key={block.id} data={block} media={this.media} title={this.title}/>
            case "jhipster_OrizenBlockDTO":
                return <Orizen key={block.id} data={block} media={this.media} title={this.title}/>
            case "jhipster_PrevoyanceTnsBlockDTO":
                return <PrevoyanceTns key={block.id} data={block} media={this.media} title={this.title}/>
            case "jhipster_ComplementaryBlockDTO":
              return <ComplementaryQuotationBlock key={block.id} data={block} media={this.media}/>
            case "jhipster_ContactBlockDTO":
              return <ContactBlock key={block.id} data={block} />
            case "jhipster_OneImageBlockDTO":
              return <OneImageBlock key={block.id} data={block} media={this.media}/>
            case "jhipster_OfficeBlockDTO":
              return <AgenciesBlock key={block.id} data={block} media={this.media}/>
            case "jhipster_PromotionalOfferBlockDTO":
              return <PromotionalOfferMobile key={block.id} data={block} media={this.media}/>
            case "jhipster_RazSimulatorBlockDTO":
              return <RazSimulatorBlock key={block.id} data={block} media={this.media}/>
            case "jhipster_OptoutBlockDTO":
              return <OptoutAtInternetBlock key={block.id}/>
            case "jhipster_AccordionBlockDTO":
              return <AccordionBlock key={block.id} data={block} />
            default:
              console.log('error on block : ', block.__typename);
              
              return <div key={block.id}></div>
          }
        })
        )}
      </div>
    )
  }
}

export default Blocks
