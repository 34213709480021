import { defaultFormat } from "moment";
import React from "react"
import { ATInternetTagService } from './../../services/global/ATInternetService';

class TwoButtonQuotation extends React.Component {
            render() {
            return(
        <section className="homeButtons">
            <div className="container">
                <div className="homeButtonsFlex">
                    <a href="/mutuelle-sante/devis/" className="buttonDevis" onClick={() => ATInternetTagService.sendNavClick({click:'demande_de_devis'})}>Demande de devis</a>
                    <a href="/mutuelle-sante/adhesion/" className="buttonAdhesion" onClick={() => ATInternetTagService.sendNavClick({click:'adhesion'})}>Adhésion</a>
                </div>
            </div>
        </section>
        )
        
    }

}
export default TwoButtonQuotation
