import React, { Component } from "react"
import AbstractMedia from "../../components/media/AbstractMedia"
import { ATInternetTagService } from '../../services/global/ATInternetService';

class PromotionalOfferMobile extends Component {
  data
  media
  componentWillMount() {
    this.data = this.props.data
    this.media = this.props.media
  }

  render() {
    return (
      <section className="promotionalOfferMobile">
        <div className="formulePresentationImage-mobile">
          <a href={this.data.offerURL} onClick={() => ATInternetTagService.sendNavClick({click:'offre'})}>
            <AbstractMedia
              abstractMedia={this.data.offerImage}
              media={this.media}
              alt={this.data.alt}
            />
          </a>
          <a href={this.data.conditionsLink}>{this.data.textConditionsLink}</a>
        </div>
      </section>
    )
  }
}

export default PromotionalOfferMobile
