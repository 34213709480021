import React from "react";
import IndependantOffer from '../images/cible-independant-offer.png'
import HomePresentationOffer from '../images/homePresentationOffer.png'
import { ATInternetTagService } from '../services/global/ATInternetService';

const CibleFormulePresentationOffer = ({codeGamme, offerConditionsText, offerConditionsLink, rootClass, offerImage, offerURL, alt }) => {
  return (
    <div className={`cibleFormulePresentationOffer ${rootClass || ''}`}>
      {offerImage &&
      <>
      <a href={offerURL} onClick={() => ATInternetTagService.sendNavClick({click:'offre'})}>
        <img src={offerImage} alt={alt ? alt : ''}/>
      </a>
      <a href={offerConditionsLink}>{offerConditionsText}</a>
      </>
      }
    </div>
  );
}


export default CibleFormulePresentationOffer;
