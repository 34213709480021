import Axios from "axios"
import { MgcgestionTokenService } from "../mgcgestionTokenService"

export default class SimulateurService {

  static submit(body) {
    const url = `${process.env.GATSBY_MGCGESTION_API_URL}/public/simulateur/calculate`
      return Axios.post(url, body)
  }
}
