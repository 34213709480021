import React, { Component } from "react"
import PrevoyanceTerritorialeQuotation from "../../components/prevoyanceTerritorialeQuotation"
import { Col } from 'react-bootstrap'
import ComplementarySended from "../../components/complementaryQuotation/complementarySended";
import ComplementaryFailed from "../../components/complementaryQuotation/complementaryFailed";

class PrevoyanceTerritoriale extends Component {

  componentDidMount() {
  }

  constructor(props) {
    super(props);
    this.state = {
        situationFamiliale: [{id: 1, libelle:''},{ id:2, libelle:''}],
        status: 'start'
    };
}
    changeStatus = val =>{
        this.setState({status: val})
    }
  render() {
    return (
        <section className="quotation-body quotation-complementary">
            <div className="container-quotation">
                <div className="quotation-form col-xs-12">
                    <div>
                        <Col md={{ span: 10, offset: 1 }} xs={12}>
                            { this.state.status === 'start' &&
                            <PrevoyanceTerritorialeQuotation situationFamiliale={this.state.situationFamiliale} changeStatus={this.changeStatus}/>
                            }
                            { this.state.status === 'sended' &&
                            <ComplementarySended title={'VOTRE DEMANDE DE RAPPEL A ÉTÉ PRISE EN COMPTE !'} quotationform={this.props.title}/>
                            }
                            { this.state.status === 'failed' &&
                            <ComplementaryFailed quotationform={this.props.title}/>
                            }
                        </Col>
                        <div className={"col-md-8 offset-md-2 col-10 offset-1 mention-quot-wizard"}>
                        <p>
                            Mutuelle Générale des Cheminots (MGC), mutuelle soumise aux dispositions du Livre II du Code de la Mutualité, SIREN n° 775 678 550, 
                            dont le Siège social est situé 2 et 4 place de l’Abbé G.Henocque 75013 Paris, et au contrôle de l’Autorité de 
                            Contrôle Prudentiel et de Résolution, ACPR, située 4 Place de Budapest - CS 92459 - 75436 Paris Cedex 09.    
                            <br/><br/>
                            Les données à caractère personnel (DCP) sont destinées à la Mutuelle MGC en sa qualité de Responsable de traitement 
                            et plus précisément aux services habilités de la Mutuelle MGC. Elles peuvent être transférées, le cas échéant, 
                            à l’organisme assureur de la garantie : Mutuelle KLESIA Mut’, soumise aux dispositions du livre II du Code de la Mutualité, 
                            inscrite au Répertoire SIRENE sous le n° SIREN 529 168 007. Siège social : 4 rue Georges Picquart, 75017 - Paris.
                            <br/>
                            Elles seront utilisées afin de répondre à votre demande de contact et, sous réserve d’avoir recueilli votre consentement, à des fins de 
                            prospection commerciale. La Mutuelle MGC conserve vos DCP pour la durée nécessaire à la réalisation des finalités pour lesquelles 
                            elles ont été collectées et ce conformément aux prescriptions légales.
                            <br/>
                            <br/>
                            En application de la réglementation française et européenne, vous disposez, ainsi que vos ayants droits :
                            <ul>
                                <li>
                                d’un droit d’accès, de rectification, d’effacement et de portabilité des données vous concernant ;
                                </li>
                                <li>
                                d’un droit d’opposition et à la limitation du traitement ;
                                </li>
                                <li>
                                du droit de retirer votre consentement à tout moment pour les finalités reposant sur ce fondement ;
                                </li>
                                <li>
                                du droit de définir des directives générales, qui peuvent être enregistrées auprès d’un tiers de confiance numérique certifié par la CNIL 
                                ou particulières relatives à la conservation, à l’effacement et à la communication de vos DCP après votre décès et qui peuvent 
                                être enregistrées auprès de la Mutuelle MGC.
                                </li>
                            </ul>
                            <br/>
                            Vous pouvez contacter le Délégué à la Protection des données personnelles de la Mutuelle par mail à&nbsp;:&nbsp;
                            <a href={'mailto:service.dpo@m-g-c.com'}>service.dpo@m-g-c.com</a> ou en adressant un courrier au siège de la 
                            Mutuelle MGC à l’attention du Délégué à la protection des données, 2 - 4 Place de l’Abbé Georges Hénocque 75637 PARIS Cedex 13.
                            <br/>
                            En cas de réclamation sur la gestion de vos données personnelles, vous avez également la possibilité de contacter la CNIL. 
                            Pour exercer l’un des droits susvisés et en savoir plus sur la politique de protection des données de la Mutuelle MGC, 
                            nous vous invitons à consulter la charte sur la protection des données publiée sur le site internet de la 
                            Mutuelle MGC :&nbsp;
                                    <a href={'https://www.mutuelleMGC.fr'}>www.mutuelleMGC.fr</a>
                        </p>
                        </div>
                    </div>
                </div>
            </div>
    </section>
    )
  }
}

export default PrevoyanceTerritoriale
