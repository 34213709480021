import React from "react"
import Alert from "react-bootstrap/Alert";

class ComplementaryFailed extends React.Component {
    
    componentDidMount() {

        window.dataLayer.push({
            event: "quotation_form",
            form_name: this.props.quotationform
        });

        window.scrollTo(0, 400)
    }

    render() {
        return (
            <Alert variant="danger" className={'alert-surcomplementaire col-lg-10 offset-lg-1'}>
                <h3>VOTRE DEMANDE N'A PAS PU ÊTRE PRISE EN COMPTE !</h3>
                <p>
                    Votre demande de devis ne peut aboutir actuellement en raison d'un
                    problème lié à notre site. Nous vous prions de bien vouloir nous
                    en excuser. Nous espérons remédier à ce problème très rapidement
                    et nous vous invitons à réitérer votre demande dans quelques minutes.
                </p>
                <p>
                    N'hésitez pas à contacter gratuitement nos conseillers ; ils
                    pourront vous renseigner. Ils sont à votre disposition du lundi au
                    vendredi de 9h00 à 12h30 et 13h30 à 18h00.
                </p>
                <ul className="quotation-alert-ul">
                    <li>Au 01 40 78 57 10</li>
                    <li>ou au 32 80 06 pour les salariés SNCF.</li>
                    <li>
                        ou sur simple demande de rappel gratuit immédiat ou différé
                    </li>
                    <li>ou par email via la page contact</li>
                </ul>
            </Alert>
        )
    }
}

export default ComplementaryFailed
