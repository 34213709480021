import React from "react"
import PropTypes from "prop-types"
import FormulaLevel from "./FormulaLevel"
import PictoMedecinesDouces from "./PictoMedecinesDouces"

const FormulaHeaderDescriptionTable = ({
  niveauSoins,
  niveauHospi,
  niveauOptique,
  niveauDentaire,
  niveauMedecineDouce,
  niveauAidesAuditives,
  codeCouleur,
  niveauMax,
  niveauAidesAuditivesDisponible
}) => (
  <div className={"formuleHeaderDescriptionTable "+codeCouleur }>
    <div className="formuleHeaderDescriptionTableCol">
      <div className="formuleHeaderDescriptionTableItem">
        <i className="icon-drug" />Soins courants
      </div>
      <div className="formuleHeaderDescriptionTableItem">
        <i className="icon-hospital" />Hospitalisation
      </div>
      <div className="formuleHeaderDescriptionTableItem">
        <i className="far fa-eye" />Optique
      </div>
      <div className="formuleHeaderDescriptionTableItem">
        <i className="icon-tooth" />Dentaire
      </div>
      <div className="formuleHeaderDescriptionTableItem">
        <i style={{ color: codeCouleur}}> <PictoMedecinesDouces width='19px' height='19px' /> </i>Médecines douces
      </div>
      {niveauAidesAuditivesDisponible &&  
      <div className="formuleHeaderDescriptionTableItem">
        <i className="fas fa-assistive-listening-systems" />Aides Auditives
      </div> }
    </div>
    <div className={"formuleHeaderDescriptionTableCol "}>
      <FormulaLevel
        level={niveauSoins}
        classIcon="icon-drug"
        rootClass="formuleHeaderDescriptionTableItem"
        maxLevel={niveauMax || 5}
      />
      <FormulaLevel
        level={niveauHospi}
        classIcon="icon-hospital"
        rootClass="formuleHeaderDescriptionTableItem"
        maxLevel={niveauMax || 5}
      />
      <FormulaLevel
        level={niveauOptique}
        classIcon="far fa-eye"
        rootClass="formuleHeaderDescriptionTableItem"
        maxLevel={niveauMax || 5}
      />
      <FormulaLevel
        level={niveauDentaire}
        classIcon="icon-tooth"
        rootClass="formuleHeaderDescriptionTableItem"
        maxLevel={niveauMax || 5}
      />
      <FormulaLevel
        level={niveauMedecineDouce}
        classIcon="icon-tooth"
        rootClass="formuleHeaderDescriptionTableItem"
        maxLevel={niveauMax || 5}
        isSvgIcon="true" />
       { niveauAidesAuditivesDisponible && <FormulaLevel
        level={niveauAidesAuditives}
        classIcon="fas fa-assistive-listening-systems"
        rootClass="formuleHeaderDescriptionTableItem"
        maxLevel={niveauMax || 5}
      /> }
    </div>
  </div>
)

FormulaHeaderDescriptionTable.propTypes = {
  lien: PropTypes.string.isRequired,
  codeCouleur: PropTypes.string,
  niveauSoins: PropTypes.number.isRequired,
  niveauHospi: PropTypes.number.isRequired,
  niveauOptique: PropTypes.number.isRequired,
  niveauDentaire: PropTypes.number.isRequired,
  niveauMedecineDouce: PropTypes.number.isRequired,
  niveauAidesAuditives: PropTypes.number.isRequired,
  niveauAidesAuditivesDisponible: PropTypes.bool
}

export default FormulaHeaderDescriptionTable
