import React from "react"
import AbstractMedia from "../../components/media/AbstractMedia"
//import HomePresentationOffer from '../../images/homePresentationOffer.png'
import { Badge } from "react-bootstrap"
import { ATInternetTagService } from '../../services/global/ATInternetService';

const HeaderFormulaBlock = ({ data, media }) => {
  const {
    title,
    tag,
    subTitle,
    description,
    conditionsLink,
    textConditionsLink,
    offerURL,
    offerImage,
    descriptionH2,
    alt,
  } = data
  return (
    <section className="pageHeader callback">
      <div className="pageHeaderContainer">
        <div className="formulePresentation">
          <h1 className="formulePresentationTitle">{title}</h1>
          <div className="d-flex align-items-start">
            <h1 className="formulePresentationSubtitle">{subTitle}</h1>
            {tag && (
              <Badge pill variant="light formulePresentationTag">
                {tag}
              </Badge>
            )}
          </div>
          <h2 className="formulePresentationText-h2">{descriptionH2}</h2>
          <p className="formulePresentationText">{description}</p>
        </div>
        <div className="formulePresentationImage">
          <a href={offerURL} onClick={() => ATInternetTagService.sendNavClick({click:'offre'})}>
            <AbstractMedia abstractMedia={offerImage} media={media} alt={alt} />
          </a>
          <a href={conditionsLink}>{textConditionsLink}</a>
        </div>
      </div>
    </section>
  )
}

export default HeaderFormulaBlock
