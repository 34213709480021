import React from "react"
import { Row, Col } from "react-bootstrap"
import { Field } from "formik"
import MgcDateField from "./mgcDateField"
import MgcTextField from "./mgcTextField"
import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import DeathBenefitCapital from "./deathBenefitCapital"
import DeathBenefitBudget from "./deathBenefitBudget"
import MgcNewDateField from "./mgcNewDateField";

class DeathBenefitConjoint extends React.Component {
  render() {
    const {
      values,
      dirty,
      errors,
      touched,
      tab,
      tabMode,
      setFieldValue,
    } = this.props
    return (
      <>
        <Row className="">
          <Col xs={12} className="quotation-radio">
            <MgcRadioToggleButtonGroup
              withoutOffset={true}
              id="civiliteConjoint"
              error={errors.civiliteConjoint}
              touched={touched.civiliteConjoint}
              value={values.civiliteConjoint}
              dirty={true}
            >
              <Field
                component={MgcRadioToggleButton}
                name="civiliteConjoint"
                id="homme"
                label={"Monsieur"}
              />
              <Field
                component={MgcRadioToggleButton}
                name="civiliteConjoint"
                id="femme"
                label={"Madame"}
              />
            </MgcRadioToggleButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MgcTextField
              dirty={dirty}
              errors={errors}
              name="nomConjoint"
              placeholder="Nom"
              className="form-control"
              withoutOffset={true}
              onChange={e => {
                setFieldValue(
                  e.target.name,
                  e.target.value
                )
              }}
    />
          </Col>
          <Col xs={12}>
            <MgcTextField
              dirty={dirty}
              errors={errors}
              name="prenomConjoint"
              placeholder="Prénom"
              className="form-control"
              withoutOffset={true}
              onChange={e => {
                setFieldValue(
                  e.target.name,
                  e.target.value
                )
              }}
    />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <span>Date de naissance:</span>
          </Col>
          <Col xs={12} className={'margin-bottom-7'}>
            <MgcNewDateField
              dirty={dirty}
              errors={errors}
              name="dateNaissanceConjoint"
              className="form-control"
              setFieldValue={setFieldValue}
              withoutOffset={true}
              values={values}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="quotation-radio">
            <MgcRadioToggleButtonGroup
              withoutOffset={true}
              id="critereCalculEtudeConjoint"
              error={errors.critereCalculEtudeConjoint}
              touched={touched.critereCalculEtudeConjoint}
              withoutOffset={true}
            >
              <Field
                component={MgcRadioToggleButton}
                name="critereCalculEtudeConjoint"
                id="capital"
                label={"Capital souhaité"}
              />
              <Field
                component={MgcRadioToggleButton}
                name="critereCalculEtudeConjoint"
                id="budget"
                label={"Budget mensuel"}
              />
            </MgcRadioToggleButtonGroup>
          </Col>
        </Row>
        <Row>
          {values.critereCalculEtudeConjoint === "capital" ? (
            <DeathBenefitCapital
              conjoint={true}
              tab={tab}
              values={values}
              dirty={dirty}
              errors={errors}
              tabMode={tabMode}
              setFieldValue={setFieldValue}
            />
          ) : values.critereCalculEtudeConjoint === "budget" ? (
            <DeathBenefitBudget
              conjoint={true}
              values={values}
              dirty={dirty}
              errors={errors}
              tabMode={tabMode}
              setFieldValue={setFieldValue}
              onChange={e => {
                setFieldValue(
                  e.target.name,
                  e.target.value
                )
              }}
            />
          ) : (
            ""
          )}
        </Row>
      </>
    )
  }
}
export default DeathBenefitConjoint