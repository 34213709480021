import React from "react"
import AbstractMedia from "../../components/media/AbstractMedia"

class ThreeIconBlock extends React.Component {
  data
  media = {}
  componentWillMount() {
    this.data = this.props.data
    this.media = this.props.media
  }
  render() {
    return (
      <section className="cibleNumbers">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3 className="cibleNumbersTitle">{this.data.mainTitle}</h3>
            </div>
          </div>
          <div className="cibleNumbersFlex">
            <div className="cibleNumbersFlexContent">
              <div>
                <AbstractMedia
                  abstractMedia={this.data.icon1}
                  media={this.media}
                  className="cibleNumbersFlexContentImage"
                  alt={this.data.alt1}
                />
              </div>
              <h5 className="cibleNumbersSubtitle"><div dangerouslySetInnerHTML={{ __html: this.data.title1 }} /></h5>
              <h4>
                <p className="cibleNumbersText"><div dangerouslySetInnerHTML={{ __html: this.data.subtitle1 }} /></p>
              </h4>
            </div>

            <div className="cibleNumbersFlexContent">
              <div>
                <AbstractMedia
                  abstractMedia={this.data.icon2}
                  media={this.media}
                  className="cibleNumbersFlexContentImage"
                  alt={this.data.alt2}
                />
              </div>
              <h5 className="cibleNumbersSubtitle"><div dangerouslySetInnerHTML={{ __html: this.data.title2 }} /></h5>
              <h4>
                <p className="cibleNumbersText"><div dangerouslySetInnerHTML={{ __html: this.data.subtitle2 }} /></p>
              </h4>
            </div>

            <div className="cibleNumbersFlexContent">
              <div>
                <AbstractMedia
                  abstractMedia={this.data.icon3}
                  media={this.media}
                  className="cibleNumbersFlexContentImage"
                  alt={this.data.alt3}
                />
              </div>
              <h5 className="cibleNumbersSubtitle"><div dangerouslySetInnerHTML={{ __html: this.data.title3 }} /></h5>
              <h4>
                <p className="cibleNumbersText"><div dangerouslySetInnerHTML={{ __html: this.data.subtitle3 }} /></p>
              </h4>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default ThreeIconBlock
